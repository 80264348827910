import { createVuetify } from 'vuetify';
import '@mdi/font/css/materialdesignicons.css';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'
import { i18n } from './i18n';
import { useI18n } from 'vue-i18n';
import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const MainTheme: ThemeTypes = {
  name: 'MainTheme',
  dark: false,
  variables: {
    'border-color': '#1e88e5',
    'carousel-control-size': 10,
  },
  colors: {
    primary: '#071330',
    // @ts-expect-error
    'panel-heading-bg': '#071330',
    'table-heading-bg': '#ebeff5',
    'primary-lighten-1': '#0C4160',
    'primary-lighten-2': '#738FA7',
    'primary-lighten-3': '#C3CEDA',
    'primary-lighten-4': '#ebeff5',
    'primary-lighten-5': '#ffffff',
    'action': '#3699ff',
    secondary: '#071330',
    info: '#03c9d7',
    success: '#56ca00',
    accent: '#FFAB91',
    warning: '#ffc107',
    error: '#f64e60',
    lightprimary: '#eef2f6',
    lightsecondary: '#ebeff5',
    lightsuccess: '#b9f6ca',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkText: '#212121',
    lightText: '#616161',
    darkprimary: '#0C4160',
    darksecondary: '#071330',
    borderLight: '#d0d0d0',
    inputBorder: '#787878',
    containerBg: '#f5f5f5',
    surface: '#fff',
    'surface-variant': '#ebeff5',
    'on-surface-variant': '#333',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#fafafa',
    primary200: '#90caf9',
    secondary200: '#b39ddb',
  }
};

const MainThemeDark: ThemeTypes = {
  name: 'MainThemeDark',
  dark: true,
  variables: {
    'border-color': '#000000',
    'carousel-control-size': 10,
  },
  colors: {
    primary: '#3699ff',
    // @ts-expect-error
    'panel-heading-bg': '#282831',
    'table-heading-bg': '#2D2D37',
    'primary-lighten-1': '#1F1A24',
    'primary-lighten-2': '#32323e',
    'primary-lighten-3': '#454856',
    'primary-lighten-4': '#3b3d49',
    'primary-lighten-5': '#32323e',
    'action': '#00aff5',
    secondary: '#FFFFFF',
    info: '#03c9d7',
    success: '#5cd59b',
    accent: '#FFAB91',
    warning: '#ffc107',
    error: '#f27362',
    lightprimary: '#1F1A24',
    lightsecondary: '#32323e',
    lightsuccess: '#b9f6ca',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkText: '#FFFFFF',
    lightText: '#FFFFFF',
    darkprimary: '#FFFFFF',
    darksecondary: '#4527a0',
    borderLight: '#495057',
    inputBorder: '#FFFFFF',
    containerBg: '#32323e',
    surface: '#32323e',
    'surface-variant': '#fafafa',
    'on-surface-variant': '#32323e',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#fafafa',
    primary200: '#90caf9',
    secondary200: '#b39ddb',
  }
};


export default createVuetify({
  components,
  directives,
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  theme: {
    defaultTheme: window.localStorage.getItem('theme') || 'MainTheme',
    themes: {
      MainThemeDark,
      MainTheme,
    }
  },
  defaults: {
    VBtn: {},
    VCard: {
      rounded: 'md'
    },
    VTextField: {
      rounded: 'lg'
    },
    VTooltip: {
      // set v-tooltip default location to top
      location: 'top'
    }
  }
});
