<script setup lang="ts">
import { computed, ref, type PropType } from 'vue';
import PanelTitle from './PanelTitle.vue';
import type { Transaction } from './types';
import { watch } from 'vue';
import { Currency, TransactionType } from '~/enums';
import TransactionForm from './TransactionForm.vue';
import Decimal from 'decimal.js';
import ButtonToggle from '@/components/inputs/ButtonToggle.vue';
import { VExpansionPanel } from 'vuetify/lib/components/index.mjs';
import { useInstrumentStore } from '@/stores/instrumentStore';
import { usePortfolioStore } from '@/stores/portfolioStore';
import AvatarVue from '@/components/Avatar.vue';
import dayjs from 'dayjs';

const emit = defineEmits([
  'update:modelValue',
  'createInstrument',
  'delete',
  'split'
]);

const props = defineProps({
  modelValue: {
    type: Object as PropType<Transaction>,
    required: true
  },
  editable: {
    type: Boolean,
    default: true
  },
  cashAccount: {
    type: Object,
    required: false
  },
  uniqueKey: {
    type: String,
    required: true
  }
});

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

const isFormValid = ref(true);
const formRef = ref(null);

const instrumentStore = useInstrumentStore();
const portfolioStore = usePortfolioStore();

const instrument = ref({});
const instrumentDetails = ref(null);
const counterpartyId = ref<number | undefined>(undefined);

const isInvestmentTransaction = computed({
  get() {
    return value.value.instrument.type !== 'cash';
  },
  set(_val) {
    if (!_val) {
      instrument.value = value.value.instrument;
      counterpartyId.value = value.value.counterparty.id;
      value.value.instrument = props.cashAccount || {};
      value.value.counterparty.id = 0;
    } else {
      value.value.instrument = instrument.value;
      value.value.counterparty.id = counterpartyId.value || props.cashAccount?.id || 0;
    }
  }
});

const diff = computed(() => {
  const nr = new Decimal(value.value?._account?.netAmount || 0).sub(new Decimal(value.value?.account?.netAmount || 0)).abs().toNumber();
  return nr >= 0.01 ? nr : 0;
});

const validate = () => {
  // @ts-ignore
  return formRef.value?.validate();
};

const isValid = computed(() => {
  return {
    transactionType: hasValidTransactionType.value,
    instrument: !!value.value.instrument.id,
    form: isFormValid.value,
    currencyCode: hasValidCurrencyCode.value,
    quantity: !!value.value.account.quantity,
    date: hasValidDate.value
  }
});

const hasValidTransactionType = computed(() => {
  return Object.values(TransactionType).includes(value.value.transactionType as any);
});

const hasValidCurrencyCode = computed(() => {
  return Object.values(Currency).includes(value.value.account.currencyCode as Currency);
});

const hasValidDate = computed(() => {
  return !!value.value.date && dayjs(value.value.date).isValid() && dayjs(value.value.date).isAfter(dayjs('1970-01-01'));
});

watch(isValid, (obj) => {
  const allValid = Object.values(obj).every((v) => v);
  value.value.control.isValid = allValid;
  value.value.control.isTransactionTypeValid = obj.transactionType;
}, { immediate: true, deep: true })

watch(() => value.value.instrument.id, async (id, oldId) => {
  validate();
  if (id && parseInt(id.toString()) > 0 && value.value.instrument.type !== 'cash') {
    instrumentDetails.value = await instrumentStore.getById(id)
  }
});

const extraInfo = computed(() => {
  const hasInstrumentDetails = !!instrumentDetails.value;
  if (!hasInstrumentDetails) {
    return;
  }
  const isAlreadyInPortfolio = !!instrumentDetails.value.portfolios.find((p) => p.id === value.value.portfolioId);
  const inPortfolios = instrumentDetails.value.portfolios.map((p) => p.name).join(', ');

  if (!isAlreadyInPortfolio) {
    if (inPortfolios) {
      return `Investeering on juba portfellis: <strong>${inPortfolios}</strong> ja see lisatakse ka <strong>${portfolioStore.selectedPortfolio.name}</strong> portfelli.`;
    } else if (isInvestmentTransaction.value) {
      return `Uus investeering lisatakse <strong>${portfolioStore.selectedPortfolio.name}</strong> portfelli.`;
    }
  }
});

const listItems = computed(() => {
  const hasInstrument = !!value.value.instrument.id;

  return [
    {
      title: 'Investeering',
      subtitle: () => {
        const text = value.value.instrument.name ?? value.value.instrument.symbol;
        if (!text) {
          return 'Vali investeering';
        } else {
          return text + ' (' + value.value.instrument.currencyCode + ')';
        }
      },
      action: () => {
        emit('createInstrument', value.value);
      },
      isValid: hasInstrument,
      showAction: true,
      logo: hasInstrument ? value.value.instrument?.logo || instrumentDetails.value?.logo : null,
      symbol: hasInstrument ? value.value.instrument?.symbol : null,
      buttonText: hasInstrument ? 'Muuda' : 'Otsi',
      buttonColor: hasInstrument ? 'secondary' : 'primary',
      visible: isInvestmentTransaction.value,
      disabled: !props.editable
    }
  ].map((item) => {
    return {
      ...item,
      icon: item.isValid ? 'mdi-check' : 'mdi-close',
      iconColor: item.isValid ? 'success' : 'error',
    }
  }).filter((item) => item.visible);
});

const instrumentCurrencyNotSame = computed(() => {
  const instrumentCurrencyCode = value.value?.instrument.currencyCode
  const isDifferent = instrumentCurrencyCode && instrumentCurrencyCode !== value.value?.account.currencyCode;

  return isDifferent && isInvestmentTransaction.value;
});
</script>

<template>
  <Lazy :component="VExpansionPanel" :minHeight="50" :unrender="true" elevation="0" class="panel" :key="uniqueKey" :value="uniqueKey">
    <panel-title v-model="value" :color="value.control.isValid ? 'light-green-lighten-5' : 'red-lighten-5'" :editable="editable" />
    <v-expansion-panel-text>
      <div class="d-flex flex-column ga-2 mb-4">
        <div class="d-flex ga-2 flex-column-reverse flex-sm-row align-center">
          <div :style="$vuetify.display.smAndUp ? 'width:210px;' : 'width:100%;'">
            <button-toggle
              v-model="isInvestmentTransaction"
              :options="[{ title: 'Investeering', value: true }, { title: 'Konto', value: false }]"
              density="compact"
              :disabled="!editable"
            />
          </div>
          <div class="text-body-1">
            <div class="font-weight-bold text-h4">Investeeringuga seotud tehing?</div>
            <div style="line-height:1em;">Kas tehing on seotud rahakontoga (näiteks: sisse/väljakanne, konto intress, teenustasu) või investeeringuga (näiteks: ost, müük, dividendid)?</div>
          </div>
        </div>
        <div class="d-flex ga-2 flex-column-reverse flex-sm-row align-center" v-for="item in listItems">
          <div :style="$vuetify.display.smAndUp ? 'width:210px;' : 'width:100%;'">
            <v-btn v-if="item.showAction" @click="item.action" :color="item.buttonColor" :disabled="item.disabled" block>
              {{ item.buttonText }}
            </v-btn>
          </div>
          <div class="d-flex align-sm-center justify-space-between" :style="$vuetify.display.smAndUp ? '' : 'width:100%;'">
            <div>
              <div>
                <div class="font-weight-bold text-h4">{{ item.title }}</div>
                <div class="d-flex align-center">
                  <AvatarVue v-if="item.logo" :logo="item.logo" :code="item.symbol ?? ''" :size="16" class="mr-1" />
                  <span :class="{'text-red': !item.isValid}">{{ item.subtitle() }}</span>
                </div>
              </div>
            </div>
            <v-btn
              :color="item.iconColor"
              :icon="item.icon"
              variant="text"
              :disabled="true"
            />
          </div>
        </div>
      </div>
      <div class="d-flex ga-2 flex-column">
        <v-alert
          v-if="instrumentCurrencyNotSame"
          :prominent="true"
          color="warning"
          theme="dark"
          border
        >
          Valitud instrumendi ja imporditud tehingu valuutad ei ole samad. Palun veendu kas valitud instrument on õige.
          Pangad kasutavad vahel populaarset sümbolit, kuigi tegelikult on alusvara kaubeldav teises valuutas ning on noteeritud teise sümboliga.
        </v-alert>
        <v-alert v-if="extraInfo">
          <span v-html="extraInfo" />
        </v-alert>
      </div>
      <div class="mt-5">
        <transaction-form
          v-model="value"
          v-model:is-valid="isFormValid"
          :account="value.instrument"
          :editable="editable"
          ref="formRef"
        />
        <div class="d-flex ga-2 flex-column">
          <v-alert
            v-if="diff"
            :prominent="true"
            color="#C51162"
            theme="dark"
            border
          >
            Erinevus imporditud väärtusest: <strong>{{ diff }}</strong>
          </v-alert>
        </div>
      </div>
      <div class="mt-5" v-if="editable">
        <v-divider />
        <div class="d-flex ga-2">
          <v-btn class="mt-4" size="small" color="error" @click="$emit('delete', value)">Kustuta</v-btn>
          <v-btn class="mt-4" size="small" color="blue" @click="$emit('split', value)">Tükelda</v-btn>
        </div>
      </div>
    </v-expansion-panel-text>
  </Lazy>
</template>


<style lang="scss">
.panel {
  border-radius: 0;
  border-bottom: 1px solid #e0e0e0;
}
</style>