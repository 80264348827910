import FullLayout from '@/layouts/full/FullLayout.vue';
import Layout from '@/views/portfolio/Layout.vue';
import Summary from '@/views/portfolio/Summary.vue';
import Import from '@/views/portfolio/import/Import.vue';
import ChooseOnboarding from '@/views/dashboard/ChooseOnboarding.vue';
import Onboarding from '@/views/dashboard/Onboarding.vue';
import AddTransaction from '@/views/portfolio/AddTransaction.vue';
import AddTransfer from '@/views/portfolio/AddTransfer.vue';
import AddInvestment from '@/views/portfolio/AddInvestment.vue';
import AddLoanAccount from '@/views/portfolio/AddLoanAccount.vue';
import AddCashAccount from '@/views/portfolio/AddCashAccount.vue';
import Settings from '@/views/portfolio/Settings.vue';
import ListCustomInstruments from '@/views/instrument/ListCustomInstruments.vue';
import ListAcounts from '@/views/account/ListAcounts.vue';
import CreatePortfolio from '@/views/portfolio/CreatePortfolio.vue';
import CreateConsolidatedView from '@/views/portfolio/CreateConsolidatedView.vue';
import Notes from '@/views/portfolio/Notes.vue';

const PortfolioRoutes = {
  path: '/portfolio',
  meta: {
    requiresAuth: true,
  },
  component: FullLayout,
  children: [
    {
      name: 'create-portfolio',
      path: 'create',
      component: CreatePortfolio,
      meta: {
        title: 'Create portfolio'
      }
    },
    {
      name: 'create-consolidated-portfolio',
      path: 'create/consolidated',
      component: CreateConsolidatedView,
      meta: {
        title: 'Create consolidated view'
      }
    },
    {
      path: ':portfolioId',
      component: Layout,
      children: [
        {
          name: 'portfolio',
          path: '',
          component: Summary,
          meta: {
            title: 'Portfolio'
          }
        },
        {
          name: 'portfolio-accounts',
          path: 'accounts',
          component: ListAcounts,
          meta: {
            title: 'Portfolio accounts'
          },
        },
        {
          name: 'portfolio-instruments',
          path: 'instruments',
          component: ListCustomInstruments,
          meta: {
            title: 'Portfolio instruments'
          },
        },
        {
          name: 'portfolio-settings',
          path: 'settings',
          component: Settings,
          meta: {
            title: 'Portfolio settings'
          },
        },
        {
          name: 'add-cash-account',
          path: 'account/cash/create',
          component: AddCashAccount,
          meta: {
            parent: 'portfolio-accounts',
            title: 'Add cash account'
          },
        },
        {
          name: 'add-loan-account',
          path: 'account/loan/create',
          component: AddLoanAccount,
          meta: {
            title: 'Add loan account'
          },
        },
        {
          name: 'add-investment',
          path: 'investment/create',
          component: AddInvestment,
          meta: {
            title: 'Add investment'
          },
        },
        {
          name: 'add-transfer',
          path: 'account/transfer',
          component: AddTransfer,
          meta: {
            title: 'Add transfer'
          },
        },
        {
          name: 'add-transaction',
          path: 'transaction/create',
          component: AddTransaction,
          meta: {
            title: 'Add transaction'
          },
        },
        {
          name: 'import',
          path: 'import',
          component: Import,
          meta: {
            title: 'Import'
          },
        },
        {
          name: 'onboarding',
          path: 'onboarding',
          component: ChooseOnboarding,
          meta: {
            parent: 'dashboard',
            title: 'Onboarding'
          },
        },
        {
          name: 'open-balance',
          path: 'enter-balance',
          component: Onboarding,
          meta: {
            parent: 'dashboard',
            title: 'Enter starting balance'
          },
        },
        {
          name: 'notes',
          path: 'notes',
          component: Notes,
          meta: {
            title: 'Notes',
            parent: 'tools'
          },
        },
      ]
    },
  ]
};

export default PortfolioRoutes;
