import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

class CommodityConfig extends InstrumentConfig {
  type: InstrumentType = InstrumentType.Commodities;
  canBeMigratedToPublic: boolean = false;
  icon: string = '👑';

  allowedTransactionTypes = [
    TransactionType.Opening,
    TransactionType.Buy,
    TransactionType.Sell,
  ]

  allowedIncomeTypes = [
    TransactionType.OtherIncome,
  ]
}

export default new CommodityConfig();
