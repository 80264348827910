import { InstrumentType, TransactionType } from "../enums";
import { InstrumentConfig } from "./Instrument.config";

class CashConfig extends InstrumentConfig {
  type: InstrumentType = InstrumentType.Cash;

  canBeMigratedToPublic: boolean = false;
  showQuantity: boolean = false;
  icon: string = '💵';

  allowedTransactionTypes = [
    TransactionType.Opening,
    TransactionType.Deposit,
    TransactionType.Withdraw,
    TransactionType.Interest,
    TransactionType.InterestPaid,
    TransactionType.OtherIncome,
    TransactionType.Fee,
    TransactionType.Tax
  ]
  allowedIncomeTypes = []
}

export default new CashConfig();
