import { i18n } from '@/plugins/i18n';
import { computed } from "vue"
import { instrumentConfig } from "~/config"
import { AssetClass, PaymentFrequency } from '~/enums';

const { t } = i18n.global

export const investmentTypeOptions = computed(() => {
  return Object.values(instrumentConfig).map((instrument) => {
    return {
      title: t(`instrumentType.${instrument.type}.title`),
      value: instrument.type
    }
  })
})

export const assetClassTypeOptions = computed(() => {
  return Object.values(AssetClass).map((assetClassKey) => {
    return {
      title: t(`asset_class.${assetClassKey}`),
      value: assetClassKey
    }
  })
})

export const paymentFrequencyOptions = computed(() => {
  return Object.values(PaymentFrequency).map((frequency) => {
    return {
      title: t(`payment_frequency.${frequency}`),
      value: frequency
    }
  })
})
