<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { rules, fieldDesign, nr, currencyFormatter } from '@/const';
import { ref, computed, watch } from 'vue';
import { TransactionType } from '~/enums';
import { useInvestmentStore } from '@/stores/investmentStore';
import { usePortfolioStore } from '@/stores/portfolioStore';
import * as I from '~/investment.schema';
import { useRouter } from 'vue-router';
import * as P from '~/portfolio.schema';
import FormContainer from '@/components/shared/FormContainer.vue';
import { useInstrumentConfig, useTranslationHelper } from "@/instrument.helper";
import FeeField from "@/components/inputs/FeeField.vue";
import type { CreateCashAccountTransactionDtoType } from "~/cash-account.schema";
import { useTransactionForm } from "@/utils/transaction.utils";
import Decimal from "decimal.js";
import AmountField from '@/components/inputs/AmountField.vue';
import PriceField from '@/components/inputs/PriceField.vue';
import AccountSearchField from "@/components/inputs/AccountSearchField.vue";
import isNil from "lodash/isNil";
import { useAppStore } from "@/stores/app";
import IncomeForm from "@/components/partial/IncomeForm.vue";

const emit = defineEmits(['update:modelValue', 'update:isValid']);
const props = defineProps(['action', 'investment', 'modelValue', 'isValid'])

const { t, te } = useI18n();

const investmentStore = useInvestmentStore();
const portfolioStore = usePortfolioStore();
const router = useRouter();
const app = useAppStore();

const isEditTotal = ref(false)
const formRef = ref(null);
const isLoading = ref(false);
const isDeleteLoading = ref(false);
const isUpdate = computed(() => {
  return props.action === 'update';
});

const getBroadKey = (key: string) => {
  const type = investment.value?.instrument?.type ?? investment.value?.type;
  return `instrumentType.${type}.${key}`
}

const investment = computed(() => props.investment ?? investmentStore.investment as I.InvestmentDtoType)
const transaction = computed(() => isUpdate.value ? investmentStore.transaction as CreateCashAccountTransactionDtoType : undefined)

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})

const validate = () => {
  // @ts-expect-error
  return formRef.value?.validate();
};

const portfolio = computed(() => {
  return portfolioStore.activePortfolio as P.PortfolioDtoType;
})

const {
  form,
  calculatedGrossAmount,
  isIncome,
  showPrice,
  showQuantity,
  resetFormForNew
} = useTransactionForm(investment, transaction)

const { iConf } = useInstrumentConfig(investment)

const showRelatedAccount = computed(() => {
  return iConf.value && (iConf.value.allowRelatedHolding[form.transactionType])
})
const showTotalCostbase = computed(() => {
  return form.transactionType === TransactionType.Opening;
})

const showFee = computed(() => {
  return ![TransactionType.Opening, TransactionType.Split, TransactionType.InterestPaid, TransactionType.LoanPrincipalPaid, TransactionType.Tax, TransactionType.Expenses, undefined, null, ''].includes(form.transactionType);
})

const finalForm = computed<CreateCashAccountTransactionDtoType>(() => {
  const hasCounterparty = showRelatedAccount.value
  const counterpartyId = hasCounterparty ? form.counterparty.id : undefined
  const accountQuantity = new Decimal(nr(isIncome.value ? form.account.netAmount : form.account.quantity));
  const accountQuantityRaw = (isIncome.value ? form.account.netAmount : form.account.quantity);


  return {
    portfolioId: portfolio.value?.id,
    instrumentId: investment.value?.instrument?.id || investment.value?.id,
    date: form.date,
    transactionType: form.transactionType,
    description: form.description,
    metadata: form.metadata,
    account: {
      id: form.account.id,
      currencyCode: form.account.currencyCode,
      quantity: accountQuantityRaw,
      netAmount: form.account.netAmount || 0,
      grossAmount: form.account.grossAmount || 0,
      fee: showFee.value ? (form.account.fee || 0) : 0,
      feeCurrencyCode: form.account.feeCurrencyCode,
      tax: showFee.value ? (form.account.tax || 0) : 0,
      taxCurrencyCode: form.account.taxCurrencyCode,
      price: showPrice.value ? form.account.price : 1,
    },
    counterparty: {
      id: counterpartyId,
      currencyCode: form.counterparty.currencyCode ?? form.account.currencyCode,
      netAmount: form.counterparty.netAmount || 0,
      grossAmount: form.counterparty.grossAmount || 0,
      fee: 0,
      feeCurrencyCode: form.counterparty.feeCurrencyCode,
      tax: 0,
      taxCurrencyCode: form.counterparty.taxCurrencyCode,
      price: 1,
    }
  }
})

// Reset fees
watch(showFee, (value) => {
  if (!value) {
    form.account.fee = 0;
  }
})

// Reset price in case of split
watch(() => form.transactionType, (transactionType) => {
  if (TransactionType.Split === transactionType) {
    form.account.price = 0;
  }
})

watch (() => [form.account.netAmount, form.account.grossAmount, form.account.fee, form.account.tax], ([netAmount, grossAmount, fee, tax], [oldNetAmount, oldGrossAmount, oldFee, oldTax]) => {
  form.counterparty.netAmount = form.account.netAmount;
  form.counterparty.grossAmount = form.account.netAmount;
  if (!isIncome.value) {
    return;
  } else {
    if (isNil(fee) && isNil(tax) && (isNil(grossAmount) || oldGrossAmount === oldNetAmount) && !isNil(form.account.netAmount)) {
      const diff = new Decimal(form.account.netAmount).minus(new Decimal(oldNetAmount)).toNumber();
      if (diff !== 0) {
        form.account.grossAmount = form.account.netAmount;
      }      
    }
  }
})

watch(() => finalForm.value, (value, oldValue) => {
  if (!props.modelValue) {
    return;
  }
  props.modelValue.description = value.description;
  props.modelValue.date = value.date;
  props.modelValue.portfolioId = value.portfolioId;
  props.modelValue.transactionType = value.transactionType;
  props.modelValue.account.quantity = value.account.quantity;
  props.modelValue.account.grossAmount = value.account.grossAmount;
  props.modelValue.account.netAmount = value.account.netAmount;
  props.modelValue.account.fee = value.account.fee;
  props.modelValue.account.tax = value.account.tax;
  props.modelValue.counterparty.id = value.counterparty.id;
  props.modelValue.counterparty.grossAmount = value.counterparty.grossAmount;
  props.modelValue.counterparty.netAmount = value.counterparty.netAmount;
  props.modelValue.counterparty.fee = value.counterparty.fee;
  props.modelValue.counterparty.tax = value.counterparty.tax;
  props.modelValue.counterparty.currencyCode = value.counterparty.currencyCode;
  props.modelValue.account.currencyCode = value.account.currencyCode;
  props.modelValue.account.feeCurrencyCode = value.account.feeCurrencyCode;
  props.modelValue.account.taxCurrencyCode = value.account.taxCurrencyCode;
  props.modelValue.counterparty.feeCurrencyCode = value.counterparty.feeCurrencyCode;
  props.modelValue.counterparty.taxCurrencyCode = value.counterparty.taxCurrencyCode;
}, { deep: true })

function update() {
  beforeApiCall();
  emit('update:modelValue', finalForm.value);
}

watch (() => props.modelValue, (modelValue) => {
  if (modelValue) {
    Object.assign(form, modelValue);
  }
}, { immediate: true })

const { tBroad } = useTranslationHelper(investment, computed(() => form.transactionType));

const translations = computed(() => {
  return {
    title: isUpdate.value ? t('transaction_page.edit_transaction_title') : t('transaction_page.create_transaction_title'),
    hints: {
      transactionType: {
        
      },
      totalCostbase: tBroad('help.total_costbase'),
      price: tBroad('field.price.help'),
      quantity: te(`transactionType.${form.transactionType}.quantity_help`) ? t(`transactionType.${form.transactionType}.quantity_help`) : ''
    },
    label: {
      dateTransactionAt: tBroad('label.date'),
      amount: tBroad('label.amount'),
      price: tBroad('label.price'),
    }
  }
})

const transactionTypeOptions = computed(() => {
  if (!iConf.value) {
    return [];
  }
  return [...iConf.value.allowedTransactionTypes, ...iConf.value.allowedIncomeTypes].map((type) => {
    const broadKey = getBroadKey(`transactionType.${type}.title`)
    return {
      title: te(broadKey) ? t(broadKey) : t(`transactionType.${type}.title`),
      value: type
    }
  })
})

watch (() => form.transactionType, (transactionType) => {
  if (!iConf.value) {
    return;
  }
  if (!iConf.value.showQuantityField(transactionType)) {
    form.account.quantity = iConf.value.getDefaultQuantity(transactionType);
  } else {
    
  }
}, { immediate: true })

watch(() => showPrice.value, (showPrice) => {
  if (!showPrice) {
    form.account.price = undefined;
  }
}, { immediate: true })

const beforeApiCall = () => {
  isLoading.value = true;
}

const onSubmit = async (addNew: boolean = false) => {
  isLoading.value = true;

  beforeApiCall();
  

  try {
    if (isUpdate.value) {
      await onUpdate();
      app.showNotice(t('transaction_page.messages.updated'), 'success');
    } else {
      await onCreate();
      app.showNotice(t('transaction_page.messages.created'), 'success');
    }
    if (addNew) {
      resetFormForNew()
    } else {
      router.go(-1);
    }
  } finally {
    isLoading.value = false;
  }
}

const onCreate = async () => {
  return investmentStore.createTransaction(investment.value.id, finalForm.value)
}

const onUpdate = async () => {
  return investmentStore.updateTransaction(investment.value.id, transaction.value.id, finalForm.value)
}

const onDelete = async () => {
  try {
    isDeleteLoading.value = true;
    await investmentStore.deleteTransaction(investment.value.id, transaction.value.id as string)
    router.push({ name: 'investment', params: { investmentId: investment.value.id } });
  } finally {
    isDeleteLoading.value = true;
  }
}

const isFormLoading = computed(() => {
  return isDeleteLoading.value || investmentStore.transaction.loading || investmentStore.investment.loading;
})

defineExpose({ update, validate });
</script>

<template>
  <FormContainer
    v-model="isValid"
    ref="formRef"
    :title="translations.title"
    :mode="isUpdate ? 'update' : 'create'"
    @submit="onSubmit"
    @delete="onDelete"
    :is-form-loading="isFormLoading"
    :is-button-loading="isLoading"
    :action="action"
    confirm-text="Are you sure you want to delete this transaction?"
    color="panel-heading-bg"
    show-add-new
  >
    <v-row>
      <v-col cols="12" lg="6">
        <DateField
          v-model="form.date"
          :label="t('label.date')"
        />
      </v-col>
      <v-col cols="12" lg="6">
        <v-select
          v-bind="(fieldDesign as any)"
          :rules="[rules.required, rules.validTransaction(transactionTypeOptions.map((o) => o.value))]"
          :items="transactionTypeOptions"
          :label="t('label.transaction_type')"
          v-model="form.transactionType"
          :disabled="isUpdate"
        />
      </v-col>
      <v-col cols="12" lg="12" v-if="showTotalCostbase">
        <AmountField
          style="flex:1;"
          v-model="form.metadata.costbase"
          v-model:currency-code-value="form.account.currencyCode"
          :label="t('label.total_costbase')"
          :hint="translations.hints.totalCostbase"
          :rules="[rules.required]"
        />
      </v-col>
      <template v-if="!isIncome">
        <v-col cols="12" :lg="showPrice ? 6 : 12" v-if="showQuantity">
          <AmountField
            style="flex:1;"
            v-model="form.account.quantity"
            v-model:currency-code-value="form.account.currencyCode"
            :hint="translations.hints.quantity"
            :label="translations.label.amount"
            :hideCurrency="![TransactionType.InterestPaid, TransactionType.Expenses].includes(form.transactionType)"
            :rules="[rules.required]"
            :holding-id="form.account.id"
            :date="form.date"
          />
        </v-col>
        <v-col cols="12" :lg="showQuantity ? 6 : 12" v-if="showPrice">
          <PriceField
            :instrument-id="finalForm.instrumentId"
            :date="form.date"
            :design="fieldDesign"
            :rules="[rules.required]"
            v-model="form.account.price"
            :hint="translations.hints.price"
            v-model:currency-code-value="form.account.currencyCode"
            :label="translations.label.price"
            :prefill="form.transactionType === TransactionType.Opening"
          />
        </v-col>
        <v-col cols="12" v-if="showFee">
          <FeeField
            v-model="form.account.fee"
            v-model:currency-code-value="form.account.feeCurrencyCode"
            :portfolio-currency="portfolio.currencyCode"
            :instrument-currency="investment.instrument?.currencyCode || investment.currencyCode"
            :field-design="fieldDesign"
            :rules="[rules.amount]"
            :label="t('label.transaction_fee')"
          />
        </v-col>
      </template>
      <template v-if="isIncome">
        <IncomeForm :form="form" />
      </template>
      <v-col cols="12" lg="12" v-if="showRelatedAccount">
        <AccountSearchField
          :fieldDesign="fieldDesign"
          v-model="form.counterparty.id"
          :label="t('label.account')"
          :disabled="isUpdate && form.counterparty.id"
          :currency-code="form.account.currencyCode"
          :hint="t('help.account')"
        />
      </v-col>
      <v-col cols="12" lg="12">
        <v-text-field
          v-bind="(fieldDesign as any)"
          type="text"
          v-model="form.description"
          :label="t('label.description')"
        />
      </v-col>
      <v-col cols="12" v-if="calculatedGrossAmount">
        <v-divider class="mb-5" />
        <v-sheet class="text-center">
          <h4 class="d-flex align-center justify-center ga-1">
            Kokku
          </h4>
          <div class="d-flex align-center justify-center ga-1">
            <AmountField
              v-if="isEditTotal"
              class="mt-n2"
              v-model="form.account.grossAmount"
              :calculated="calculatedGrossAmount"
              hideCurrency
              :design="{variant: 'underlined', density: 'compact', 'single-line': true}"
              style="max-width:100px;"
            />
            <div v-else style="font-size:1.2em;" class="font-weight-light">{{ currencyFormatter(form.account.grossAmount, form.account.currencyCode) }}</div>
            <v-icon @click="isEditTotal = !isEditTotal" color="primary" size="16">mdi-pencil</v-icon>
            <span v-if="form.account.fee && parseFloat(form.account.fee) !== 0" class="mt-1 text-caption">(+{{ currencyFormatter(form.account.fee, form.account.feeCurrencyCode) }} tasud)</span>
          </div>
        </v-sheet>
      </v-col>
    </v-row>
  </FormContainer>
</template>
