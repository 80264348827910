<template>
  <div class="d-flex justify-space-between flex-row">
    <div class="d-flex flex-row align-center">
      <avatar :size="size" :code="codeValue" :logo="logoValue" class="mr-2" />
      <router-link :to="toValue" class="font-weight-bold text-truncate" :class="textClasses">{{ codeValue }}</router-link>
      <router-link style="max-width:200px;" v-if="!isCash && !isLoan" :to="toValue" class="ml-2 d-none d-lg-inline text-truncate" :class="textClasses">{{ nameValue }}</router-link>
    </div>
    <v-chip v-if="showPortfolios && !!holding.parent && holding.parent.portfolios.length > 1" color="secondary" size="x-small" class="d-none d-lg-flex">
      {{ holding.portfolios[0].name }}
    </v-chip>
  </div>
</template>

<script setup lang="ts">
import { computed, h } from 'vue';
import Avatar from './Avatar.vue';
import { HoldingType } from '~/enums';

const props = defineProps({
  code: String,
  logo: String,
  route: null,
  name: String,
  isCash: Boolean,
  isLoan: Boolean,
  showPortfolios: Boolean,
  holding: Object,
  size: {
    default: 24
  }
});

const codeValue = computed(() => {
  if (props.code) {
    return props.code;
  } else if ([HoldingType.Loan, HoldingType.Cash].includes(props.holding.type)) {
    return props.holding.symbol;
  } else if ([HoldingType.Investment]) {
    return props.holding.instrument?.displayName;
  }
});

const nameValue = computed(() => {
  if (props.name) {
    return props.name;
  } else if ([HoldingType.Loan, HoldingType.Cash].includes(props.holding.type)) {
    return props.holding.name;
  } else if ([HoldingType.Investment]) {
    return props.holding.instrument?.name;
  }
});

const toValue = computed(() => {
  if (props.route) {
    return props.route;
  } else if ([HoldingType.Loan, HoldingType.Cash].includes(props.holding.type)) {
    return `/${props.holding.type === 'loan' ? 'loan' : 'account'}/${props.holding.id}`
  } else if ([HoldingType.Investment]) {
    return `/investment/${props.holding.id}`;
  }
});

const logoValue = computed(() => {
  if (props.logo) {
    return props.logo;
  } else if ([HoldingType.Loan, HoldingType.Cash].includes(props.holding.type)) {
    return props.holding.logo;
  } else if ([HoldingType.Investment]) {
    return props.holding.instrument?.logo;
  }
});

const textClasses = computed(() => {
  return {
    'text-secondary': props.holding.hasActiveInvestments !== false,
    'text-decoration-line-through': props.holding.isArchived,
    'text-disabled': props.holding.isArchived || props.holding.hasActiveInvestments === false
  };
});
</script>