import { computed, type ComputedRef, type Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";
import { currencyFormatter, percentFormatter, colors } from '@/const'
import { useAppStore } from "./stores/app";

const defOpts = computed(() => ({ valueKey: 'percentage', height: 350 }))

function getUniqueColor(index, tone = 0) {
  const numColors = colors.length;

  if (index < numColors) {
    return colors[index]; // Use the predefined color
  }

  // Generate a new unique color (e.g., using HSL)
  const hue = (index * 137.508) % 360; // Golden angle approximation
  return `hsl(${hue}, 70%, 50%)`;
}

export function addLogo (chart: any, isDark: boolean) {
  // Add the logo image
  chart.renderer.image(
    `https://static.portfellow.com/portfellow-${isDark ? 'w' : 'b'}.svg?v=3`, // URL of the logo image
    chart.chartWidth - 110, // X position (adjust as needed)
    chart.chartHeight - 25, // Y position (adjust as needed)
    100, // Width of the logo
    25 // Height of the logo
  )
}

export function useAllocationChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
}> = defOpts) {
  const { t } = useI18n();
  const theme = useTheme();

  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value.filter((group: any) => group.value > 0);
    const isDark = theme.current.value.dark;
    const isPercentage = opts.isPercentage ?? false;

    return {
      chart: {
        type: 'pie',
        height: opts.height,
        backgroundColor: 'transparent',
        events: {
          load: function() {
            addLogo(this);
          },
          fullscreenOpen: function() {
            // Change background color when fullscreen is opened
            this.update({
                chart: {
                    backgroundColor: isDark ? '#00000' : '#FFFFFF' // Fullscreen chart background color
                }
            });
          },
          fullscreenClose: function() {
              // Revert background color when fullscreen is closed
              this.update({
                  chart: {
                      backgroundColor: 'transparent' // Revert to original background color
                  }
              });
          },
          render: function () {
              var chart = this,
                  centerX = chart.plotLeft + (chart.plotWidth / 2),
                  centerY = chart.plotTop + (chart.plotHeight / 2);
              
              // Remove existing text (to avoid duplication on redraw)
              if (chart.centerText) {
                  chart.centerText?.destroy();
              }

              // Add text in the center
              chart.centerText = chart.renderer.text(opts.centerText?.(), centerX, centerY)
                  .attr({
                      'text-anchor': 'middle', // Center the text
                      zIndex: 5
                  })
                  .css({
                    fontSize: '20px',
                    color: isDark ? 'white' : '#0C4160'
                  })
                  .add();
          },
        }
      },
      exporting: {
        enabled: opts.exporting ?? false,
        buttons: {
          contextButton: {
            align: 'right',  // Horizontal alignment (left, center, right)
            verticalAlign: 'top',  // Vertical alignment (top, middle, bottom)
            x: -10,  // Horizontal offset
            y: 0  // Vertical offset
          }
        },
        chartOptions: {
          chart: {
            backgroundColor: '#FFFFFF',
          }
        }
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      title: {
        text: '',
      },
      tooltip: {
        pointFormat: opts.pointFormat ?? (isPercentage ? `{series.name}: <b>{point.percentage:.1f}%</b>` :  '{series.name}: <b>{point.value}</b>'),
        pointFormatter: function () {
          let value = '';
          if (isPercentage) {
            value = percentFormatter(this.percentage / 100)
          } else {
            value = currencyFormatter(this.y, opts.currencyCode)
          }

          return `<b>${value}</b>`
        }
      },
      legend: {
        enabled: !opts.compact,
        align: 'center',
        verticalAlign: 'bottom',
        layout: 'horizontal',
        itemWidth: opts.compact ? 100 : undefined,
        floating: false,
        itemStyle: {
          color: isDark ? 'white' : '#0C4160'
        },
        x: 0,
        y: 20
      },
      plotOptions: {
        pie: {
          innerSize: '60%',
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: opts.dataLabels ?? true,
            format: '{point.percentage:.1f}%',
            style: {
              color: isDark ? 'white' : '#0C4160',
              fontSize: '16px'
            }
          },
          showInLegend: true,
        }
      },
      navigation: {
        buttonOptions: {
            verticalAlign: 'bottom',
            y: 0
        }
      },
      series: [{
          name: opts.name ?? t('report.percentage'),
          data: list.map((group: any) => {
            const dashed = group.value < 0
            const y = Math.round(group[opts.valueKey] * (isPercentage ? 10000 : 1)) / (isPercentage ? 100 : 1)

            return {
              name: group.title ?? group.name,
              dashStyle: dashed ? 'longdash' : undefined,
              borderColor: dashed ? 'red' : undefined,
              color: dashed ? 'rgba(255, 0, 0, 0.5)' : undefined,
              y: Math.abs(y),
              value: y
            }
          })
      }]
    }
  });

  return {
    chartOptions
  }
}

export function useWaterfallChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
}> = defOpts) {
  const { t } = useI18n();
  const theme = useTheme();
  const app = useAppStore();

  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value;
    const isDark = theme.current.value.dark;
    const isPercentage = opts.isPercentage ?? false;

    const items = list.map((group: any) => {
      const y = Math.round(group[opts.valueKey] * (isPercentage ? 10000 : 1)) / (isPercentage ? 100 : 1)

      return {
        name: group.title ?? group.name,
        color: y < 0 ? colors[5] : colors[0],
        y: y,
        value: y
      }
    });

    const total = items.reduce((acc, item) => acc + item.y, 0);

    return {
      chart: {
        type: 'waterfall',
        height: opts.height,
        backgroundColor: 'transparent'
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        pointFormat: opts.pointFormat ?? (isPercentage ? `{series.name}: <b>{point.percentage:.1f}%</b>` :  '{series.name}: <b>{point.value}</b>'),
        pointFormatter: function () {
          let value = '';
          if (isPercentage) {
            value = percentFormatter(this.percentage / 100)
          } else {
            value = currencyFormatter(this.y, opts.currencyCode)
          }

          return `<b>${value}</b>`
        }
      },
      legend: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
        labels: {
          enabled: true,
          style: {
            color: isDark ? '#ffffff' : '#0C4160'
          }
        },
        gridLineColor: isDark ? '#32323e' : 'rgb(222, 231, 248)',
        lineColor: isDark ? '#495057' : 'rgb(222, 231, 248)'
      },
      yAxis: {
        title: {
          text: ''
        },
        labels: {
          enabled: true,
          style: {
            color: isDark ? '#ffffff' : '#0C4160'
          }
        },
        lineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
        lineWidth: 1,
        gridLineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
        minorGridLineWidth: 0,
      },
      series: [{
          name: opts.name ?? t('report.percentage'),
          data: items.filter(i => i.y > 0).concat([{
            name: 'Brutoväärtus',
            isSum: true,
            color: colors[1]
          }]).concat(items.filter(i => i.y < 0)).concat([{
            name: 'Netoväärtus',
            isIntermediateSum: true,
            color: colors[3]
          }]),
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.point.isSum || this.point.isIntermediateSum || this.y < 0) {
                if (app.hideBalances) {
                  return '?';
                }
                return currencyFormatter(this.y, opts.currencyCode, 0, 0)
              }
              return percentFormatter(this.y / total)
            }
          },
          pointPadding: 0
      }]
    }
  });

  return {
    chartOptions
  }
}

export function useTreemapChart (groups: Ref<any>, _opts: ComputedRef<{
  valueKey?: string,
  dataLabels?: boolean,
  isPercentage?: boolean | Ref<boolean>,
  name?: string,
  pointFormat?: string,
  exporting?: boolean,
  centerText?: () => string,
  height?: number
  currencyCode?: string
  compact?: boolean
}> = defOpts) {
  const chartOptions = computed(() => {
    const opts = _opts.value
    const list = groups.value;

    const points = list.reduce((collect: any, group: any, n: number) => {
      collect.push({
        id: group.key,
        name: group.title ?? group.name,
        value: group[opts.valueKey],
        color: getUniqueColor(n)
      });
      if (group.items?.length) {
        group.items.forEach((item: any, i: number) => {
          collect.push({
            parent: group.key,
            id: `${group.key}-${i}`,
            name: item.title ?? item.name,
            value: item[opts.valueKey],
            color: getUniqueColor(n, i)
          });
          if (item.items?.length) {
            item.items.forEach((subitem: any, j: number) => {
              collect.push({
                parent: `${group.key}-${i}`,
                id: `${group.key}-${i}-${j}`,
                name: subitem.title ?? subitem.name,
                value: subitem[opts.valueKey],
                color: getUniqueColor(n, j)
              });
            });
          }
        });
      }
      return collect;
    }, []);

    console.log(list)

    return {
      chart: {
        type: 'treemap',
        height: opts.height,
        backgroundColor: 'transparent'
      },
      title: {
        text: '',
      },
      credits: {
        enabled: false,
        text: 'portfellow.com',
        href: 'http://www.example.com'
      },
      exporting: {
        enabled: false,
      },
      tooltip: {
        pointFormatter: function () {
          const value = currencyFormatter(this.value, opts.currencyCode)
          return `${this.name}:<br /> <b>${value}</b>`
        }
      },
      legend: {
        enabled: false,
      },
      series: [{
        name: 'Portfolio',
        type: 'treemap',
        data: points,
        layoutAlgorithm: 'squarified',
        allowDrillToNode: true,
        dataLabels: {
          enabled: false
        },
        levels: [{
          level: 1,
          dataLabels: {
            enabled: true
          },
          borderWidth: 3,
          levelIsConstant: false
        }, {
          level: 2,
          dataLabels: {
            style: {
              fontSize: '14px'
            }
          }
        }],
        accessibility: {
          exposeAsGroupOnly: true
        },
      }]
    }
  });

  return {
    chartOptions
  }
}