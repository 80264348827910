<script lang="ts" setup>
import AmountField from '@/components/inputs/AmountField.vue';
import DisplayCard from '@/components/shared/DisplayCard.vue';
import { fieldDesign, rules } from '@/const';
import dayjs from 'dayjs';
import { computed, ref } from 'vue';
import InvestmentForm from '../investment/InvestmentForm.vue';
import AccountProviderField from '@/components/inputs/AcountProviderField.vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { useRouter } from 'vue-router';
import Note from '@/components/Note.vue';
import cloneDeep from 'lodash/cloneDeep';
import PriceField from '@/components/inputs/PriceField.vue';
import HelpIcon from '@/components/HelpIcon.vue';
import { useAppStore } from '@/stores/app';
import { TrackingType } from '~/enums';
import { useI18n } from 'vue-i18n';

const portfolioStore = usePortfolioStore();

const emptyInvestmentRow = {
  instrument: null,
  quantity: null,
  price: null,
  cost: null
}

const emptyAccountRow = {
  name: null,
  provider: undefined,
  trackingType: TrackingType.None,
  balances: [{
    amount: 0,
    currencyCode: portfolioStore.portfolio?.currencyCode || 'EUR'
  }],
}

const router = useRouter();
const app = useAppStore();
const { t } = useI18n();

const isLoading = ref(false);
const formRef = ref(null)
const isFormValid = ref(false);
const showCreateInvestment = ref(false);
const selectedInvestmentRowId = ref(-1);

const investmentRows = ref([cloneDeep(emptyInvestmentRow)])
const accountRows = ref([cloneDeep(emptyAccountRow)])

const form = ref({
  date: dayjs().startOf('year').format('YYYY-MM-DD')
})

const design = computed(() => {
  return {
    ...fieldDesign.value,
    density: 'compact'
  }
});

const searchInstrument = (index: number) => {
  showCreateInvestment.value = true;
  selectedInvestmentRowId.value = index;
}

const onInvestmentCreated = (investment) => {
  investmentRows.value[selectedInvestmentRowId.value].instrument = investment;
  showCreateInvestment.value = false;
}

const onInvestmentSelected = (investment) => {
  investmentRows.value[selectedInvestmentRowId.value].instrument = investment;
  showCreateInvestment.value = false;
}

const save = async () => {
  if (isLoading.value) {
    return;
  }
  // @ts-expect-error
  if (!(await formRef.value.validate()).valid) {
    return;
  }

  try {
    isLoading.value = true;

    const accounts = accountRows.value.filter(row => !!row.name).map((row) => {
      return {
        name: row.name as unknown as string,
        providerId: row.provider?.id as unknown as number,
        trackingType: row.trackingType as unknown as TrackingType,
        balances: row.balances.map((balance) => {
          return {
            amount: balance.amount as unknown as number,
            currencyCode: balance.currencyCode as unknown as string
          }
        })
      }
    })

    const investments = investmentRows.value.filter(row => !!row.instrument).map((row) => {
      return {
        instrument: {
          uniqueId: row.instrument?.uniqueId as unknown as string,
          id: row.instrument?.id as unknown as number,
          symbol: row.instrument?.symbol as unknown as string,
          exchange: row.instrument?.exchange as unknown as string,
          currencyCode: row.instrument?.currencyCode as unknown as string,
        },
        quantity: row.quantity as unknown as number,
        price: row.price as unknown as number,
        cost: row.cost as unknown as number
      }
    })

    if (accounts.length === 0 && investments.length === 0) {
      return;
    }

    await portfolioStore.importOpenBalances(portfolioStore.selectedPortfolioId, {
      date: form.value.date,
      accounts,
      investments,
    })
    app.showNotice(t('onboarding.opening_balance.success'), 'success');
    router.push({ name: 'portfolio', params: { portfolioId: portfolioStore.selectedPortfolioId } });
  } finally {
    isLoading.value = false;
  }
}

const cashTrackingOptions = computed(() => {
  return [{
    title: t('account_page.tracking_type.none'),
    value: TrackingType.None,
  }, {
    title: t('account_page.tracking_type.balance'),
    value: TrackingType.Balance,
  }, {
    title: t('account_page.tracking_type.full'),
    value: TrackingType.Full
  }]
})
</script>

<template>
  <PageLayout :heading="$t('onboarding.opening_balance.title')" hide-back>
    <v-form ref="formRef" v-model="isFormValid">
      <v-row>
        <v-col cols="12" md="7">
          <p class="text-body-1">
            {{ $t('onboarding.opening_balance.info') }}
          </p>
          <v-sheet class="mt-4" color="transparent">
            <p class="font-weight-bold mb-3 text-h3">
              {{ $t('onboarding.opening_balance.date') }}:</p>
            <DateField
              v-model="form.date"
              :label="$t('onboarding.opening_balance.date')"
            />
          </v-sheet>
          <p class="mt-4 text-body-1">
            {{ $t('onboarding.opening_balance.info_2') }}
          </p>
          <p class="mt-4 text-body-1">
            {{ $t('onboarding.opening_balance.info_3') }}
          </p>
        </v-col>
        <v-col cols="12" md="5">
          <Note>
            <div class="d-flex ga-3 flex-column text-body-1">
              <h3>
                {{ $t('onboarding.opening_balance.info_4') }}
              </h3>
              <p>
                {{ $t('onboarding.opening_balance.info_5') }}
              </p>
              <p>
                {{ $t('onboarding.opening_balance.info_6') }}
              </p>
              <p>
                {{ $t('onboarding.opening_balance.info_7') }}
              </p>
              <p><a target="_blank" class="text-blue" href="https://portfellow.com/help/kuidas-alustada-portfellow-kasutamisega/">
                {{ $t('onboarding.opening_balance.info_8') }}
              </a></p>
            </div>
          </Note>
        </v-col>
      </v-row>

      <DisplayCard
        class="mt-10"
        :title="$t('onboarding.opening_balance.investments')"
        color="panel-heading-bg"
        :showDivider="false"
        elevation="0"
        variant="outlined"
      >
        <template v-slot:description>
          <spa v-html="$t('onboarding.opening_balance.investments_info')" />
        </template>
        <v-table class="rounded-0" density="compact">
          <thead>
            <tr class="bg-primary-lighten-4">
              <th style="width:25%;">
                {{ $t('label.investment') }}
              </th>
              <th style="width:25%;">
                {{ $t('label.quantity') }}
                <HelpIcon
                  color="primary"
                  :help-text="$t('onboarding.opening_balance.quantity_help')"
                />
              </th>
              <th style="width:25%;">
                {{ $t('label.price') }}
                <HelpIcon
                  color="primary"
                  :help-text="$t('onboarding.opening_balance.price_help')"
                />
              </th>
              <th style="width:25%;">
                {{ $t('label.costbase') }}
                <HelpIcon
                  color="primary"
                  :help-text="$t('onboarding.opening_balance.costbase_help')"
                />
              </th>
              <th style="width:20px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in investmentRows" :key="index">
              <td>
                <div class="d-flex align-center">
                  <div>
                    <v-btn @click="searchInstrument(index)" variant="text">
                      <template v-if="!row.instrument">
                        <v-icon v-if="!row.instrument">mdi-feature-search</v-icon>
                        {{ $t('label.search') }}
                      </template>
                      <template v-else>
                        <v-icon> mdi-pencil-box </v-icon>
                        {{ $t('label.edit') }}
                      </template>
                    </v-btn>
                  </div>
                  <div>{{ row.instrument?.name }}</div>
                </div>
              </td>
              <td style="vertical-align:top;">
                <AmountField
                  :disabled="!row.instrument"
                  :label="$t('label.quantity')"
                  :rules="[rules.required]"
                  :design="(design as any)"
                  v-model="row.quantity"
                  hideCurrency
                />
              </td>
              <td style="vertical-align:top;">
                <PriceField
                  :disabled="!row.instrument"
                  :design="design"
                  v-model="row.price"
                  :currency-code-value="row.instrument?.currencyCode"
                  :label="$t('label.price')"
                  :instrument-id="row.instrument?.id"
                  :date="form.date"
                  :rules="[rules.required]"
                />
              </td>
              <td style="vertical-align:top;">
                <AmountField
                  :disabled="!row.instrument"
                  :currency-code-value="row.instrument?.currencyCode"
                  :design="(design as any)"
                  v-model="row.cost"
                  :label="$t('label.costbase')"
                  :rules="[rules.required]"
                />
              </td>
              <th class="text-right">
                <v-btn
                  color="error"
                  variant="text"
                  @click="investmentRows.splice(index, 1)"
                  icon="mdi-delete"
                />
              </th>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <v-btn
                  color="primary"
                  size="small"
                  variant="tonal"
                  @click="investmentRows.push({ ...emptyInvestmentRow })"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('onboarding.opening_balance.add_investment') }}
                </v-btn>
              </td>
            </tr>
          </tfoot>
        </v-table>
      </DisplayCard>


      <DisplayCard
        class="mt-10"
        :title="$t('onboarding.opening_balance.accounts')"
        color="panel-heading-bg"
        :showDivider="false"
        elevation="0"
        variant="outlined"
      >
        <template v-slot:description>
          <span v-html="$t('onboarding.opening_balance.accounts_info')" />
        </template>
        <v-table class="rounded-0" density="compact">
          <thead>
            <tr class="bg-primary-lighten-4">
              <th style="width:25%;">
                {{ $t('label.account_name') }}
                <HelpIcon
                  color="primary"
                  :help-text="$t('onboarding.opening_balance.account_name_help')"
                />
              </th>
              <th style="width:25%;">
                {{ $t('label.account_provider') }}
                <HelpIcon
                  color="primary"
                  :help-text="$t('onboarding.opening_balance.account_provider_help')"
                />
              </th>
              <th style="width:25%;">
                {{ t('label.accoubt_tracking_type') }}
                <HelpIcon color="primary" max-width="400">
                  <strong>{{ $t('account_page.tracking_type.none') }}</strong> - {{ $t(`account_page.form.${TrackingType.None}_help`) }}
                  <v-divider class="my-1"></v-divider>
                  <strong>{{ $t('account_page.tracking_type.balance') }}</strong> - {{ $t(`account_page.form.${TrackingType.Balance}_help`) }}
                  <v-divider class="my-1"></v-divider>
                  <strong>{{ $t('account_page.tracking_type.full') }}</strong> - {{ $t(`account_page.form.${TrackingType.Full}_help`) }}
                  <v-divider class="my-1"></v-divider>
                  {{ $t('onboarding.opening_balance.account_tracking_type_help') }}
                </HelpIcon>
              </th>
              <th style="width:25%;">
                {{ $t('label.balance') }}
                <HelpIcon
                  color="primary"
                  :help-text="$t('onboarding.opening_balance.balance_help')"
                />
              </th>
              <th style="width:20px;"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in accountRows" :key="index">
              <td>
                <v-text-field
                  v-bind="(design as any)"
                  v-model="row.name"
                  :label="$t('label.account_name')"
                  :rules="[rules.required]"
                />
              </td>
              <td>
                <AccountProviderField
                  :fieldDesign="design"
                  v-model="row.provider"
                  :rules="[rules.required]"
                />
              </td>
              <td>
                <v-select
                  v-bind="(design as any)"
                  v-model="row.trackingType"
                  :items="cashTrackingOptions"
                  :rules="[rules.required]"
                />
              </td>
              <td>
                <div class="d-flex flex-column ga-2 py-2" v-if="row.trackingType !== TrackingType.None">
                  <div v-for="(balance, k) in row.balances" :key="k" class="d-flex flex-row align-center ga-2">
                    <AmountField
                      :design="(design as any)"
                      v-model="balance.amount"
                      v-model:currency-code-value="balance.currencyCode"
                      :allCurrencies="true"
                      :label="$t('label.balance')"
                      :rules="[rules.required]"
                    />
                    <v-btn
                      v-if="k > 0"
                      icon="mdi-close"
                      color="error"
                      variant="text"
                      @click="row.balances.splice(k, 1)"
                      size="small"
                    />
                    <v-tooltip :text="$t('onboarding.opening_balance.add_currency')" v-else>
                      <template v-slot:activator="{ props }">
                        <v-btn
                          v-bind="props"
                          icon="mdi-plus"
                          color="green"
                          variant="text"
                          @click="row.balances.push({ amount: null, currencyCode: '' })"
                          size="small"
                        />
                      </template>
                    </v-tooltip>
                  </div>
                </div>
              </td>
              <th class="text-right">
                <v-btn
                  color="error"
                  icon="mdi-delete"
                  @click="accountRows.splice(index, 1)"
                  variant="text"
                />
              </th>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="5">
                <v-btn
                  color="primary"
                  size="small"
                  variant="tonal"
                  @click="accountRows.push(cloneDeep(emptyAccountRow))"
                >
                  <v-icon>mdi-plus</v-icon>
                  {{ $t('onboarding.opening_balance.add_account') }}
                </v-btn>
              </td>
            </tr>
          </tfoot>
        </v-table>
      </DisplayCard>

      <div class="text-right">
        <v-btn
          :disabled="isLoading || isFormValid === false"
          :loading="isLoading"
          size="x-large"
          class="mt-10"
          color="blue"
          @click="save">
          {{ $t('label.save') }}
        </v-btn>
      </div>
    </v-form>
  </PageLayout>
  <v-dialog :max-width="900" v-model="showCreateInvestment" scrollable>
    <InvestmentForm
      type="create"
      :variant="'search'"
      @created="onInvestmentCreated"
      @selected="onInvestmentSelected"
      :title="$t('label.choose_instrument')"
    />
  </v-dialog>
</template>

<style lang="scss" scoped>
:deep(tbody),
:deep(tfoot) {
  td {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}
:deep(tbody tr:nth-of-type(even)) {
  background-color: rgba(0, 0, 0, .03);
}
</style>
