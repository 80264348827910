<script setup lang="ts">
import UpgradeLock from '../UpgradeLock.vue';
import HelpIcon from '../HelpIcon.vue';

const props = defineProps({
  title: String,
  variant: {
    type: String,
    default: 'elevated',
  },
  bg: {
    type: String,
    default: 'bg-surface',
  },
  color: {
    type: String,
    default: null,
  },
  description: {
    type: String,
    default: '',
  },
  showDivider: {
    type: Boolean,
    default: true,
  },
  elevation: {
    type: [String, Number],
    default: 4,
  },
  showLock: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
  helpText: {
    type: String,
    default: null,
  },
  helpIconColor: {
    type: String,
    default: 'primary-light',
  }
})
</script>

<template>
  <v-card :variant="$vuetify.theme.current.dark ? 'outlined' : variant" :elevation="elevation" :class="bg" style="overflow-y:auto;">
    <div :class="`d-flex align-center justify-space-between bg-${color} pa-4`" v-if="props.title">
      <div class="d-flex flex-column flex-sm-row align-sm-center" :class="{'ga-4': $vuetify.display.smAndUp}">
        <v-card-title class="pa-0">
          <v-icon v-if="icon">{{ icon }}</v-icon>
          {{ props.title }}
          <HelpIcon v-if="helpText" :helpText="helpText" :color="helpIconColor" />
          <UpgradeLock size="20" v-if="showLock" />
          <slot name="append-title"></slot>
        </v-card-title>
        <div class="description font-weight-light">
         <slot name="description">{{ description }}</slot>
        </div>
      </div>
      <slot name="actions"></slot>
    </div>
    <v-divider v-if="showDivider"></v-divider>
    <slot></slot>
  </v-card>
</template>

<style lang="scss" scoped>
.description {
  line-height: 1.1em;
  font-size: 0.85em;

  @media (min-width: 600px) {
    border-left: 1px solid gray;
    padding-left: 12px;
  }
}
</style>