<script setup lang="ts">
import { watch } from 'vue';
import CustomInstrumentFormVue from './CustomInstrumentForm.vue';
import { useRoute } from 'vue-router';
import { useInstrumentStore } from '@/stores/instrumentStore'
import { computed } from 'vue';
import type { InvestmentDtoType } from '~/investment.schema';

const route = useRoute();
const instrumentStore = useInstrumentStore()

const instrument = computed(() => {
  return instrumentStore.instrument.data;
})

watch(() => {
  return route.params.instrumentId as string;
}, async (instrumentId: string) => {
  if (!instrumentId) {
    return;
  }
  instrumentStore.getById(parseInt(instrumentId));

}, { immediate: true })

const settingsRoute = (investment: InvestmentDtoType) => {
  return {
    name: 'investment-settings', 
    params: { investmentId: investment.id } 
  }
}
</script>

<template>
  <v-row>
    <v-col cols="12" lg="6">
      <CustomInstrumentFormVue :instrument="instrument" :is-loading="instrumentStore.instrument.isLoading" />
    </v-col>
    <v-col cols="12" lg="6">
      <DisplayCard :title="$t('instrument_page.in_portfolios_title')" variant="text" color="panel-heading-bg">
        <v-table density="compact" :hover="true" v-if="instrument">
          <thead>
            <tr class="bg-table-heading-bg">
              <th class="text-left">
                {{ $t('label.instrument_name') }}
              </th>
              <th class="text-left">
                {{ $t('label.instrument_code') }}
              </th>
              <th class="text-left">
                {{ $t('label.portfolio') }}
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(investment) in instrument.investments" :key="investment.id">
              <td><router-link :to="`/investment/${investment.id}`" class="text-primary font-weight-bold">{{ investment.name }}</router-link></td>
              <td>{{ investment.symbol }}</td>
              <td>{{ investment.portfolio?.name }}</td>
              <td class="text-right">
                <v-btn variant="text" icon="mdi-pencil" :to="settingsRoute(investment)" size="x-small" />
              </td>
            </tr>
          </tbody>
        </v-table>
      </DisplayCard>
    </v-col>
  </v-row>
</template>