<script setup lang="ts">
import { currencyFormatter } from '@/const';
import { computed } from 'vue';
import { useTheme } from 'vuetify';
import { addLogo } from '@/chart.helper';

const props = defineProps({
  values: {
    type: Array,
    required: true
  },
  categories: {
    type: Array,
    required: true
  },
  isLoading: {
    type: Boolean,
    required: true
  },
  currencyCode: {
    type: String,
    required: false,
    default: 'EUR'
  },
  round: {
    type: Boolean,
    required: false,
    default: true
  },
  label: {
    type: String,
    required: false
  },
  valueKey: {
    type: String,
    required: false,
    default: 'value'
  }
});

const theme = useTheme();

const chartOptions = computed(() => {
  const isDark = theme.current.value.dark;

  return {
    chart: {
      type: 'column',
      backgroundColor: 'transparent',
      events: {
        load: function() {
          addLogo(this, isDark);
        },
        fullscreenOpen: function() {
            // Change background color when fullscreen is opened
            this.update({
                chart: {
                    backgroundColor: isDark ? '#00000' : '#FFFFFF' // Fullscreen chart background color
                }
            });
          },
          fullscreenClose: function() {
              // Revert background color when fullscreen is closed
              this.update({
                  chart: {
                      backgroundColor: 'transparent' // Revert to original background color
                  }
              });
          },
      }
    },
    exporting: {
      enabled: true,

    },
    rangeSelector: {
      selected: 1,
      enabled:false
    },
    navigation: {
      buttonOptions: {
        theme: {
          fill: 'transparent',
        }
      }
    },
    scrollbar: {
      enabled: false
    },
    title:{
      text:''
    },
    tooltip: {
      formatter: function() {
          var s = '<b>'+ this.x +'</b>',
              sum = 0;

          this.points.forEach((point) => {
            s += '<br/><span style="color:' + point.series.color + '">\u25CF</span> '+ point.series.name +': '+
                  currencyFormatter(point.y, props.currencyCode);
              sum += point.y;
          });
          

          if (this.points.length > 1) {
            s += '<br/><br />Kokku: ' + currencyFormatter(sum, props.currencyCode);
          }
          
          return s;
      },
      shared: true,
      crosshairs: true,
      shadow: false,
      borderWidth: 1,
    },
    xAxis: {
      categories: props.categories,
      visible: true,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        enabled: true,
        style: {
          color: isDark ? '#ffffff' : '#0C4160'
        }
      },
      minorTicks: false,
      gridLineWidth: 1,
      gridLineDashStyle: 'dash',
      gridLineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
      lineColor: isDark ? '#495057' : '#0C4160'
    },
    yAxis: {
      visible: true,
      title: {
        text: null
      },
      gridLineWidth: 1,
      gridLineColor: isDark ? '#495057' : 'rgb(222, 231, 248)',
      minorGridLineWidth: 0,
      labels: {
        enabled: true,
        style: {
          color: isDark ? '#ffffff' : '#0C4160'
        }
      },
    },
    legend:{
      enabled:true,
      itemStyle: {
        color: isDark ? '#ffffff' : '#0C4160'
      }
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderColor: 'rgba(255,255,255,0.1)',
      },
    },
    series: props.values
  }
})
</script>

<template>
  <highcharts :options="chartOptions" v-if="values.length >= 0 && !isLoading" />
  <div v-else class="mt-5">
    <v-skeleton-loader :boilerplate="!isLoading" height="180" style="width:100%;" type="ossein" />
  </div>
</template>