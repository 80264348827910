import { Static, Type } from '@sinclair/typebox'
import { TransactionCategory, TransactionType } from './enums'
import { AccountDto, PaginatedResponseDto, TransactionDto } from './common.schema'

export const excludeTransactionFields: any[] = ['id', 'relatedTransactionId', 'relatedHolding', 'isIncome', 'balance', 'totalValue', 'baseCurrencyCode']

export const CashAccountTransactionDto = Type.Intersect([TransactionDto, Type.Object({
  transactionType: Type.Enum(TransactionType)
})])

export const CashAccountTransactionsDto = Type.Intersect([PaginatedResponseDto, Type.Object({
  items: Type.Array(CashAccountTransactionDto)
})])

export const UpdateAccountBalanceDto = Type.Object({
  date: Type.String({ format: 'date' }),
  currencyCode: Type.String(),
  balance: Type.Number()
})

export const CashAccountBalanceHistorDto = Type.Intersect([PaginatedResponseDto, Type.Object({
  items: Type.Array(Type.Object({
    id: Type.Integer(),
    date: Type.String({ format: 'date' }),
    balance: Type.Number(),
    currencyCode: Type.String()
  }))
})])

export const CreateAccountDto =  Type.Intersect([
  Type.Omit(AccountDto, ['id', 'type']),
  Type.Object({
    customGroups: Type.Optional(Type.Array(Type.String()))
  })
])

export const CreateCashAccountTransactionDto = Type.Object({
  id: Type.Optional(Type.Integer()),
  category: Type.Optional(Type.Enum(TransactionCategory)),
  instrumentId: Type.Optional(Type.Integer()),
  portfolioId: Type.Integer(),
  date: Type.String({ format: 'date' }),
  transactionType: Type.Enum(TransactionType),
  description: Type.Optional(Type.String()),
  counterpartyAccount: Type.Optional(Type.String()),
  counterpartyName: Type.Optional(Type.String()),
  referenceId: Type.Optional(Type.String()),
  referenceIds: Type.Optional(Type.Array(Type.String())),
  metadata: Type.Optional(Type.Object({
    costbase: Type.Optional(Type.Number()),
  })),
  account: Type.Object({
    id: Type.Optional(Type.Integer()),
    currencyCode: Type.String(),
    quantity: Type.Number(),
    netAmount: Type.Optional(Type.Number()),
    grossAmount: Type.Optional(Type.Number()),
    fee: Type.Optional(Type.Number()),
    feeCurrencyCode: Type.String(),
    tax: Type.Optional(Type.Number()),
    taxCurrencyCode: Type.Optional(Type.String()),
    price: Type.Number()
  }),
  counterparty: Type.Object({
    id: Type.Optional(Type.Integer()),
    currencyCode: Type.Optional(Type.String()),
    netAmount: Type.Optional(Type.Number()),
    grossAmount: Type.Optional(Type.Number()),
    fee: Type.Optional(Type.Number()),
    feeCurrencyCode: Type.Optional(Type.String()),
    tax: Type.Optional(Type.Number()),
    taxCurrencyCode: Type.Optional(Type.String()),
    price: Type.Optional(Type.Number())
  })
})

export const MoveInvestmentPortfolioDto = Type.Object({
  portfolioId: Type.Integer()
})

export const AccountBalanceDto = Type.Object({
  holingId: Type.Integer(),
  balance: Type.Number(),
  currencyCode: Type.String()
})

export const AddAccountCurrency = Type.Object({
  currencyCode: Type.String()
})

export type TransactionDtoType = Static<typeof TransactionDto>
export type AccountDtoType = Static<typeof AccountDto>
export type AccountCreateDtoType = Static<typeof CreateAccountDto>
export type CashAccountTransactionDtoType = Static<typeof CashAccountTransactionDto>
export type CreateCashAccountTransactionDtoType = Static<typeof CreateCashAccountTransactionDto>
export type CashAccountTransactionsDtoType = Static<typeof CashAccountTransactionsDto>
export type MoveInvestmentPortfolioDtoType = Static<typeof MoveInvestmentPortfolioDto>
export type AccountBalanceDtoType = Static<typeof AccountBalanceDto>
export type UpdateAccountBalanceDtoType = Static<typeof UpdateAccountBalanceDto>
export type AddAccountCurrencyType = Static<typeof AddAccountCurrency>
export type CashAccountBalanceHistorDtoType = Static<typeof CashAccountBalanceHistorDto>

export const GetAccountSchema = {
  tags: ['account'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: AccountDto
  }
}

export const GetAccountBalanceSchema = {
  tags: ['account'],
  params: Type.Object({
    id: Type.Integer()
  }),
  response: {
    200: AccountBalanceDto
  }
}

export const GetAccountsSchema = {
  tags: ['account'],
  response: {
    200: Type.Array(AccountDto)
  }
}

export const CreateAccountSchema = {
  tags: ['account'],
  body: CreateAccountDto,
  response: {
    200: AccountDto
  }
}

export const UpdateAccountSchema = {
  tags: ['account'],
  body: Type.Partial(CreateAccountDto),
  response: {
    200: AccountDto
  }
}

export const UpdateAccountBalanceSchema = {
  tags: ['account'],
  body: UpdateAccountBalanceDto,
  response: {
    200: Type.Boolean()
  }
}

export const AddAccountCurrencySchema = {
  tags: ['account'],
  body: AddAccountCurrency,
  response: {
    200: AccountDto
  }
}

export const CreateCashAccountTransactionSchema = {
  tags: ['account'],
  body: CreateCashAccountTransactionDto,
  response: {
    200: CashAccountTransactionDto
  }
}

export const DeleteCashAccountSchema = {
  tags: ['account'],
  response: {
    200: Type.Boolean()
  }
}

export const UpdateCashAccountTransactionSchema = {
  tags: ['account'],
  body: CreateCashAccountTransactionDto,
  response: {
    200: CashAccountTransactionDto
  }
}

export const GetCashAccountTransactionSchema = {
  tags: ['account'],
  response: {
    200: CreateCashAccountTransactionDto
  }
}

export const DeleteCashAccountTransactionSchema = {
  tags: ['account'],
  response: {
    200: Type.Boolean()
  }
}

export const GetCashAccountTransactionsSchema = {
  tags: ['account'],
  response: {
    200: CashAccountTransactionsDto
  }
}

export const GetCashAccountBalanceHistorySchema = {
  tags: ['account'],
  response: {
    200: CashAccountBalanceHistorDto
  }
}

export const MoveInvestmentPortfolioSchema = {
  tags: ['account'],
  response: {
    200: Type.Boolean()
  }
}
