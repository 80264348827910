import PerformanceFilters from "@/components/PerformanceFilters.vue";
import DisplayCard from "@/components/shared/DisplayCard.vue";
import DateField from "../components/inputs/DateField.vue"
import PageLayout from "@/layouts/PageLayout.vue";
import HelpIcon from "@/components/HelpIcon.vue";
import Filter from "@/components/table/Filter.vue";
import Note from "@/components/Note.vue";
import Lazy from "@/components/Lazy.vue";
import UpgradeLock from "@/components/UpgradeLock.vue";
import Dialog from "@/components/Dialog.vue";
import AmountField from "@/components/inputs/AmountField.vue";
import AccountLink from "@/components/shared/AccountLink.vue";
import DropdownMenu from "@/components/DropdownMenu.vue";
import ButtonToggle from "@/components/inputs/ButtonToggle.vue";
import HideBalancesButton from "@/components/HideBalancesButton.vue";
import PartialReportAlert from "@/components/PartialReportAlert.vue";

import type { App } from "vue";

export function useApp(app: App) {
  app.component("PerformanceFilters", PerformanceFilters);
  app.component("DisplayCard", DisplayCard);
  app.component("PageLayout", PageLayout);
  app.component("DateField", DateField)
  app.component("HoverHelpIcon", HelpIcon)
  app.component("Filter", Filter)
  app.component("Note", Note)
  app.component("Lazy", Lazy)
  app.component("UpgradeLock", UpgradeLock)
  app.component("Dialog", Dialog)
  app.component("AmountField", AmountField)
  app.component("AccountLink", AccountLink)
  app.component("DropdownMenu", DropdownMenu)
  app.component("ButtonToggle", ButtonToggle)
  app.component("HideBalancesButton", HideBalancesButton)
  app.component("PartialReportAlert", PartialReportAlert)
}
