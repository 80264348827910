<template>
  <v-tooltip :max-width="maxWidth" v-model="isVisible" :open-on-hover="false" transition="fade-transition">
    <template v-slot:activator="{ props }">
      <v-icon v-bind="props" :color="color" :size="size" icon="mdi-help-circle-outline" @mouseenter="showTooltip" @mouseleave="hideTooltip" />
    </template>
    <div @mouseenter="showTooltip" @mouseleave="hideTooltip" style="z-index:3000 !important;position:relative !important;pointer-events: auto;">
      <slot>{{ helpText }}</slot>
    </div>
  </v-tooltip>

</template>

<script setup lang="ts">
import { ref } from 'vue';

defineProps({
  helpText: {
    type: String,
    default: null,
  },
  color: {
    type: String,
    default: 'primary',
  },
  size: {
    type: String,
    default: '16',
  },
  maxWidth: {
    type: String,
    default: '300',
  },
});

const isVisible = ref(false);
const time = ref(0);

const showTooltip = () => {
  clearTimeout(time.value);
  isVisible.value = true;
};

const hideTooltip = () => {
  time.value = setTimeout(() => {
    isVisible.value = false;
  }, 300);
};
</script>