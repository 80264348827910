import { type Ref, computed, ref } from "vue";
import { useI18n } from "vue-i18n";

export function useFutureCashflowReport (report: Ref<any>) {
  const { t } = useI18n();

  const isChartCummulative = ref(false)

  const chartCategories = computed(() => {
    return report.value?.summary?.groupSteps ?? [];
  });

  const summaryItems = computed(() => {
    return Object.entries(report.value?.summary?.byType ?? {}).map(([key, value]) => {
      return {
        type: key,
        // @ts-ignore
        ...value
      }
    }).filter((item) => {
      return item.totalCount > 0
    }).sort((a, b) => {
      return b.base - a.base
    })
  })

  const chartData = computed(() => {
    return summaryItems.value.map((item) => {
      let sum = 0;
      return {
        name: t(`cashflowTransactionType.${item.type}.title`),
        data: chartCategories.value.map((category: string) => {
          const itemValue = item.grouped[category] ?? 0
          sum += itemValue
          const show = isChartCummulative.value ? sum : itemValue
          return item.grouped[category] || isChartCummulative.value ? Math.round(show * 100) / 100 : null
        })
      }
    })
  })

  return {
    chartData,
    chartCategories,
    summaryItems,
    isChartCummulative
  }
}
