import FullLayout from '@/layouts/full/FullLayout.vue';
import Layout from '@/views/investment/Layout.vue';
import Investment from '@/views/investment/Investment.vue';
import Settings from '@/views/investment/Settings.vue';
import CreateTransaction from '@/views/investment/CreateTransaction.vue';
import EditTransaction from '@/views/investment/EditTransaction.vue';
import CreateIncome from '@/views/investment/CreateIncome.vue';
import EditIncome from '@/views/investment/EditIncome.vue';

const InvestmentRoutes = {
  path: '/investment',
  meta: {
    requiresAuth: true,
    parent: 'portfolio-instruments'
  },
  component: FullLayout,
  children: [
    {
      path: ':investmentId',
      component: Layout,
      children: [
        {
          name: 'investment',
          path: '',
          component: Investment,
          meta: {
            hideHeading: true,
            title: 'Investment'
          }
        },
        {
          name: 'investment-settings',
          path: 'settings',
          component: Settings,
          meta: {
            title: 'Investment settings'
          }
        },
        {
          name: 'investment-create-transaction',
          path: 'transaction/create',
          component: CreateTransaction,
          meta: {
            title: 'Create investment transaction'
          }
        },
        {
          name: 'investment-edit-transaction',
          path: 'edit-transaction/:transactionId',
          component: EditTransaction,
          meta: {
            title: 'Edit investment transaction'
          }
        },
        {
          name: 'investment-create-income',
          path: 'create-income',
          component: CreateIncome,
          meta: {
            title: 'Create investment income'
          }
        },
        {
          name: 'investment-edit-income',
          path: 'edit-income/:incomeId',
          component: EditIncome,
          meta: {
            title: 'Edit investment income'
          }
        },
        {
          name: 'investment-create-loan-transaction',
          path: 'create-loan-transaction',
          component: () => import('@/views/investment/CreateLoanTransaction.vue'),
          meta: {
            title: 'Create loan transaction'
          }
        },
        {
          name: 'investment-edit-loan-transaction',
          path: 'edit-loan-transaction/:loanTransactionId',
          component: () => import('@/views/investment/EditLoanTransaction.vue'),
          meta: {
            title: 'Edit loan transaction'
          }
        },
        {
          name: 'investment-manage-price',
          path: 'manage-price',
          component: () => import('@/views/investment/ManagePrice.vue'),
          meta: {
            hideHeading: true,
            title: 'Manage investment price'
          }
        },
        {
          name: 'investment-import-price',
          path: 'import-price',
          component: () => import('@/views/investment/ImportPrice.vue'),
          meta: {
            title: 'Import investment price'
          }
        },
      ]
    },
  ]
};

export default InvestmentRoutes;
