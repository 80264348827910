<script setup lang="ts">
import { currencyFormatter, percentFormatter, colors } from '@/const';
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useReport } from '@/report.helper';
import { useAllocationChart, useTreemapChart, useWaterfallChart } from '@/chart.helper';
import { useAppStore } from '@/stores/app';
import { useAuthStore } from '@/stores/authStore';

const props = defineProps({
  report: {
    type: Object,
    required: true,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
});

const { t } = useI18n();
const appStore = useAppStore();
const authStore = useAuthStore();

const chartType = ref<'pie' | 'waterfall' | 'tree'>(authStore.getUserPreference(`assetAllocation.chartType`, 'pie'));

watch(() => chartType.value, (value) => {
  authStore.setUserPreference(`assetAllocation.chartType`, value);
});

const { groupsFlattened, flatList } = useReport(computed(() => props.report));

const colSize = computed(() => {
  return {
    lg: chartType.value === 'waterfall' ? 12 : 6
  }
});

const chartInput = computed(() => ({
  height: chartType.value === 'waterfall' ? 400 : 300,
  compact: true,
  valueKey: 'value',
  dataLabels: false,
  name: '',
  currencyCode: props.report.currencyCode,
  pointFormat: '<b>{point.y}</b>',
  centerText: () => appStore.hideBalances ? '' : `<strong class="balance">${currencyFormatter(props.report.value, props.report.currencyCode, 0, 0)}</strong><br /><span class="font-weight-thin text-caption balance">${t('label.value')}</span>`
}))

const { chartOptions: allocationChartOptions } = useAllocationChart(groupsFlattened, chartInput);
const { chartOptions: waterfallChartOptions } = useWaterfallChart(groupsFlattened, chartInput);
const { chartOptions: treeMapChartOptions } = useTreemapChart(groupsFlattened, chartInput);


const chart = computed(() => {
  if (chartType.value === 'pie') {
    return allocationChartOptions.value;
  } else if (chartType.value === 'tree') {
    return treeMapChartOptions.value;
  } else {
    return waterfallChartOptions.value;
  }
})

const total = computed(() => {
  return props.report.value;
})

const items = computed(() => {
  return groupsFlattened.value
    .filter(x => x.value > 0)
    .slice(0, 7)
    .map((x, n) => ({
      title: x.title || x.name,
      value: x.value,
      color: colors[n],
      allocation: x.value / total.value,
      currencyCode: props.report?.currencyCode,
    }));
});

defineExpose({ colSize });
</script>

<template>
  <DisplayCard :title="props.title" class="overflow-hidden">
    <template v-slot:actions>
      <div class="d-flex ga-2 align-center">
        <v-btn 
          color="primary"
          variant="tonal"
          v-if="$vuetify.display.smAndUp"
          :to="{
            name: 'diversity-report',
            params: {
              portfolioId: report.portfolioId || 0
            },
          }">{{ $t('label.view_report') }}</v-btn>
        <ButtonToggle v-model="chartType" mode="icon" density="compact" :options="[{
          title: 'mdi-chart-pie-outline',
          value: 'pie'
        }, {
          title: 'mdi-chart-tree',
          value: 'tree'
        }, {
          title: 'mdi-chart-waterfall',
          value: 'waterfall'
        }]" />
      </div>
    </template>
    <v-row>
      <v-col cols="12" :md="chartType === 'pie' && !isLoading ? 6 : 12">
        <highcharts
          :options="chart"
          v-if="groupsFlattened.length > 0 && !isLoading"
          :key="chartType + '-' + appStore.hideBalances"
          :class="{'mt-4': chartType === 'waterfall'}"
        />
        <div v-else class="ma-4">
          <v-skeleton-loader :boilerplate="!props.isLoading" height="280" style="width:100%;" type="ossein" />
        </div>
      </v-col>
      <v-col cols="12" md="6" v-if="chartType === 'pie' && !isLoading">
        <v-list density="compact" lines="one">
          <v-list-item v-for="(item, n) in items" :title="item.title" density="compact" class="py-0">
            <template v-slot:prepend>
              <v-avatar size="24" rounded="md" :color="item.color" />
            </template>
            <template v-slot:subtitle>
              <span class="balance">
                {{ currencyFormatter(item.value, item.currencyCode) }}
              </span>
            </template>
            <template v-slot:append>
              {{ percentFormatter(item.allocation) }}
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </DisplayCard>
</template>