<script setup lang="ts">
import { computed, ref } from 'vue';
import { fieldDesign } from '@/const';
import { usePortfolioStore } from '@/stores/portfolioStore';
import InvestmentTransactionForm from '@/views/investment/TransactionForm.vue';
import AccountTransactionForm from '@/views/account/TransactionForm.vue';
import IncomeTransactionForm from '@/views/investment/IncomeForm.vue';
import { watch } from 'vue';
import { useTransaction } from '@/transaction.helper';

const emit = defineEmits(['save', 'close', 'createInstrument', 'update:modelValue']);

const props = defineProps({
  modelValue: {
    type: Object,
    required: true
  },
  cashAccountId: {
    type: Number,
    required: false
  }
})

const item = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emit('update:modelValue', value)
  }
});

enum TransactionOption {
  InvestmentTransaction = 'investment_transaction',
  InvestmentIncome = 'investment_income',
  AccountRelated = 'account_related'
}

const portfolioStore = usePortfolioStore();

const form = ref<any>(null);
const transactionType = ref<TransactionOption>();
const investment = ref<any>(null);
const investments = computed(() => portfolioStore.portfolioInvestments);
const cashAccounts = computed(() => portfolioStore.portfolioAccounts.item);
const cashAccount = computed(() => {
  return cashAccounts.value.find((account: any) => account.id === props.cashAccountId);
});
const selectedSplit = ref<any>(null);
const showInstrumentSelectModal = ref(false);
const showTransferSelectModal = ref(false);
const splits = ref<any>(null);
const isFormValid = ref(false);

const transactionTypes = computed(() => {
  return [{
    title: 'Investment buy/sell transaction',
    value: TransactionOption.InvestmentTransaction
  }, {
    title: 'Investment income transaction',
    value: TransactionOption.InvestmentIncome
  }, {
    title: 'Cash account related',
    value: TransactionOption.AccountRelated
  }]
})

const empty = {
  type: null,
  account: null,
  transaction: null,
  isValid: null
}

const importedTotalAmount = computed(() => {
  return parseFloat(item.value.quantity || 0) + item.value.related.reduce((acc, item) => {
    return acc + parseFloat(item.quantity || 0);
  }, 0);
})

watch(item, (value) => {
  if (!value.splits) {
    splits.value = [Object.assign({}, empty, {
      transaction: {
        dateTransactionAt: item.value.date,
        description: item.value.description,
        quantity: Math.abs(parseFloat(item.value.quantity)),
      }
    })];
  } else {
    splits.value = [...value.splits];
  }
}, { immediate: true });

const edit = (split: any) => {
  selectedSplit.value = split;
  selectedSplit.value.isValid = false;
  split.transaction.relatedHoldingId = TransactionOption.AccountRelated !== split.type ? props.cashAccountId : undefined

  if ([TransactionOption.InvestmentTransaction, TransactionOption.InvestmentIncome].includes(split.type)) {
    showInstrumentSelectModal.value = true;
  } else if ([TransactionOption.AccountRelated].includes(split.type)) {
    showTransferSelectModal.value = true;
  }
}

const showIncomeForm = (split: any) => {
  return split.type === TransactionOption.InvestmentIncome && split.investment;
}

const next = () => {
  form.value?.update();
  selectedSplit.value.isValid = true;
  showInstrumentSelectModal.value = false;
  showTransferSelectModal.value = false;
}

const save = () => {
  item.value.splits = splits.value
  item.value.mappingIsValid = isValid.value;
  emit('update:modelValue', item.value);
}

const cancel = () => {
  showInstrumentSelectModal.value = false;
  showTransferSelectModal.value = false;
  emit('close')
}

const createInvestment = () => {
  emit('createInstrument')
}

const addSplit = () => {
  splits.value.push(Object.assign({}, empty, {
    transaction: {
      dateTransactionAt: item.value.date,
      description: item.value.description,
      quantity: difference.value
    }
  }));
}

const remove = (split: any) => {
  splits.value = splits.value.filter((item: any) => item !== split);
}

const reset = (split: any) => {
  split.isValid = null;
  split.transaction = {
    dateTransactionAt: item.value.date,
    description: item.value.description,
    quantity: Math.abs(parseFloat(item.value.quantity))
  }
}

const totalSplitAmount = computed(() => {
  return splits.value.reduce((acc, split) => {
    return acc + amount(split);
  }, 0);
})

const difference = computed(() => {
  return Math.abs(parseFloat(item.value.quantity)) - totalSplitAmount.value;
})

const isValid = computed(() => {
  return difference.value === 0 && splits.value.every((split: any) => {
    return split.isValid;
  });
})

const amount = (split: any) => {
  const transactionInfo = useTransaction(split.transaction);
  if (split.transaction.currencyCode === cashAccount.value.currencyCode) {
    return Math.round(transactionInfo.value.valueInLocalCurrency*100)/100;
  }
  return Math.round(transactionInfo.value.valueInBaseCurrency*100)/100;
}
</script>

<template>
  
  <v-card>
    <v-card-title>Map transaction</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-table density="compact" class="import">
        <thead>
          <tr>
            <th>Type</th>
            <th>Amount</th>
            <th>Counterparty</th>
            <th>Description</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(split, i) in splits" :key="i"
            :class="{
              'bg-red-lighten-5': !split.isValid,
              'bg-green-lighten-5': split.isValid
            }"
          >
            <td style="width:30%;">
              <v-select
                @update:model-value="reset(split)"
                v-model="split.type"
                :items="transactionTypes"
                density="compact"
                class="no-radius"
                variant="outlined"
                v-bind="fieldDesign"
                hide-details
              />
            </td>
            <td>
              {{ amount(split) }}
            </td>
            <td>
              {{ item.counterparty }}
            </td>
            <td>
              {{ split.transaction }}
              {{ split.transaction?.description }}
            </td>
            <td>
              <div class="d-flex align-center" v-if="split.type">
                <v-btn @click="edit(split)" variant="flat" color="secondary" size="x-small">
                  <span v-if="!split.investment">Configure</span>
                  <span v-else>Edit</span>
                </v-btn>
              </div>
            </td>
            <td class="text-right">
              <v-btn @click="remove(split)" color="red" icon size="x-small">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="5">
              <div class="text-center">
                <v-btn variant="outlined" size="small" @click="addSplit" color="green">Add split</v-btn>
              </div>
            </td>
          </tr>
        </tfoot>
      </v-table>
      <v-list style="max-width:300px;">
        <v-list-item title="Imported transaction amount">
          <template v-slot:append>
            {{ importedTotalAmount }}
          </template>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item title="Mapped transactions amount">
          <template v-slot:append>
            {{ totalSplitAmount }}
          </template>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item title="Difference">
          <template v-slot:append>
            {{ difference }}
          </template>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="text-right">
      <v-btn :disabled="!isValid" @click="save" color="secondary">Save</v-btn>
      <v-btn variant="outlined" class="ml-3" @click="cancel" color="primary">Cancel</v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog :max-width="900" v-model="showInstrumentSelectModal" v-if="selectedSplit" :scrollable="true">
    <v-card>
      <v-card-item>
        <v-card-title>Select Investment</v-card-title>
      </v-card-item>
      <v-divider></v-divider>
      <div class="pa-6">
        <v-autocomplete
          label="Investment"
          v-model="selectedSplit.investment"
          :items="investments"
          item-title="instrument.name"
          item-value="id"
          outlined
          hide-details
          dense
          v-bind="fieldDesign"
          return-object
        >
          <template v-slot:append>
            <v-btn @click="createInvestment" color="primary" size="large">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
        </v-autocomplete>
        <div class="pt-6"></div>
        <IncomeTransactionForm
          ref="form"
          v-if="showIncomeForm(selectedSplit)"
          :investment="selectedSplit.investment"
          v-model="selectedSplit.transaction"
          v-model:is-valid="isFormValid"
          action="import"
        />
        <InvestmentTransactionForm
          ref="form"
          v-else-if="selectedSplit.investment"
          :investment="selectedSplit.investment"
          v-model="selectedSplit.transaction"
          v-model:is-valid="isFormValid"
          action="import"
        />
      </div>
      <v-divider></v-divider>
      <div class="pa-6 text-sm-right text-center">
        <v-btn @click="next" color="primary" :disabled="!isFormValid">Next</v-btn>
      </div>
    </v-card>
  </v-dialog>
  <v-dialog :max-width="900" v-model="showTransferSelectModal" v-if="selectedSplit" :scrollable="true">
    <v-row>
      <v-col cols="12">
        <v-card variant="flat">
          <v-card variant="outlined">
            <v-card-item>
              <v-card-title>Cash Account Transaction</v-card-title>
            </v-card-item>
            <v-divider></v-divider>
            <div class="pa-6">
              <AccountTransactionForm
                ref="form"
                :account="cashAccount"
                v-model="selectedSplit.transaction"
                v-model:is-valid="isFormValid"
                action="import"
              />
            </div>
            <v-divider></v-divider>
            <div class="pa-6 text-sm-right text-center">
              <v-btn @click="next" color="primary" :disabled="!isFormValid">Next</v-btn>
            </div>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>
