<template>
  <h3>Interactive Brokers</h3>
  <p class="mt-2 text-body-1">Loo järgneva juhendi põhjal endale raport. Raporti pead looma ainult ühe korra, hiljem saad seda uuesti kasutada või seadistada automaatse impordi.</p>
  <v-sheet class="mt-4 pa-2 rounded-md">
    <ol class="ml-7 text-body-2">
      <li>Logi sisse oma <a href="https://www.interactivebrokers.com.au/sso/Login" class="text-blue" target="_blank">Interactive Brokers</a> kontole</li>
      <li>Vali menüüst (Menu) raportid (Reporting) ja vali "Flex Queries"</li>
      <li>Raporti loomiseks vajuta "+" märgile "Activity Flex Query" real</li>
      <li>Määra raportile nimi (Query Name)</li>
      <li>Vali raha tehingud (Cash Transactions)</li>
      <li>Avanenud aknast vali kõik valikud peale "Summary"</li>
      <li>Vajuta "Vali kõik" (Select all) ja vajuta "Save"</li>
      <li>Vali tehingud (Trades)</li>
      <li>Avanenud aknast vali "Executions"</li>
      <li>Vajuta "Vali kõik" (Select all) ja vajuta "Save"</li>
      <li>Vali kindlasti XML formaat (Format)</li>
      <li>Vali periood "Last 30 Calendar days" (Period) - seda saad hiljem muuta</li>
      <li>Vali kuupäeva formaadiks (Date Format) yyyy-MM-dd ja aja formaadiks (Time Format) HH:mm:ss</li>
      <li>Vajuta jätka (Continue)</li>
      <li>Vajuta loo (Create)</li>
      <li>Vajuta loodud raporti kõrval olevat "->" (Run) nuppu</li>
      <li>Vali sobiv periood ja formaat "XML"</li>
      <li>Vajuta "Run"</li>
      <li>Lae loodud fail Portfellowsse üles</li>
    </ol>
  </v-sheet>
  <p class="mt-5">
    <!--<a class="text-blue" href="#">Loe juhendit</a>-->
  </p>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const tab = ref(0)
</script>