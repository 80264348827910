<script setup lang="ts">
import { fieldDesign, rules, nr } from '@/const';
import { ref, computed, watch } from 'vue';
import { TransactionType } from '~/enums';
import { usePortfolioStore } from '@/stores/portfolioStore';
import * as S from '~/cash-account.schema';
import { useRouter } from 'vue-router';
import FormContainer from '@/components/shared/FormContainer.vue';
import * as P from '~/portfolio.schema';
import { useI18n } from 'vue-i18n';
import { useLoanStore } from '@/stores/loanAccountStore';
import { useTransactionForm } from '@/utils/transaction.utils';
import Decimal from 'decimal.js';
import AmountField from '@/components/inputs/AmountField.vue';
import AccountSearchField from "@/components/inputs/AccountSearchField.vue";
import { useAppStore } from '@/stores/app';

defineExpose({ update });
const emit = defineEmits(['update:modelValue', 'update:isValid', 'updated']);
const props = defineProps(['action', 'mode', 'account', 'modelValue', 'isValid', 'showAccountSelector'])

const app = useAppStore();
const loanStore = useLoanStore();
const portfolioStore = usePortfolioStore();
const router = useRouter();
const { t } = useI18n();

const selectedLoan = ref(null);
const formRef = ref(null);
const isLoading = ref(false);
const isUpdate = computed(() => props.action === 'update');
const isHoldingMode = computed(() => props.mode === 'holding');

const isValid = computed({
  get() {
    return props.isValid;
  },
  set(value) {
    emit('update:isValid', value);
  }
})

const translations = computed(() => {
  return {
    title: isUpdate.value ? t('account_page.form.update_transaction') : t('account_page.form.create_transaction'),
    hints: {
      transactionType: {
        [TransactionType.Opening]: t(`transactionType.${TransactionType.Opening}.help`),
        [TransactionType.Transfer]: t(`transactionType.${TransactionType.Transfer}.help`),
        [TransactionType.Interest]: t(`transactionType.${TransactionType.Interest}.help`),
        [TransactionType.Deposit]: t(`transactionType.${TransactionType.Deposit}.help`),
        [TransactionType.Withdraw]: t(`transactionType.${TransactionType.Withdraw}.help`)
      },
      relatedAccount: t('label.related_account_hint')
    },
    label: {
      dateTransactionAt: form.transactionType === TransactionType.Opening ? 'Opening balance date' : 'Date of transaction'
    }
  }
})

const loan = computed(() => props.account || selectedLoan.value || loanStore.loan as S.AccountDtoType)
const portfolio = computed(() => portfolioStore.activePortfolio as P.PortfolioDtoType)
const cashAccounts = computed(() => portfolioStore.portfolioAccounts.item)
const transaction = computed(() => isUpdate.value ? loanStore.transaction as S.CreateCashAccountTransactionDtoType : undefined)

const { form, resetFormForNew } = useTransactionForm(loan, transaction);

const transactionTypeOptions = computed(() => {
  return [{
    value: TransactionType.Opening,
  }, {
    value: TransactionType.Withdraw
  }, {
    value: TransactionType.LoanPrincipalPaid,
  }, {
    value: TransactionType.InterestPaid
  }, {
    value: TransactionType.Fee
  }].map((option) => {
    return {
      ...option,
      title: t(`loanTransactionType.${option.value}.title`)
    }
  })
})


function update() {
  beforeApiCall();
  emit('update:modelValue', form);
}

watch (() => props.modelValue, (modelValue) => {
  if (modelValue) {
    Object.assign(form, modelValue);
  }
}, { immediate: true })

const showRelatedAccount = computed(() => {
  return [
    TransactionType.Deposit,
    TransactionType.Withdraw,
    TransactionType.InterestPaid,
    TransactionType.LoanPrincipalPaid,
  ].includes(form.transactionType as TransactionType)
})

const finalForm = computed<S.CreateCashAccountTransactionDtoType>(() => {
  const hasCounterparty = showRelatedAccount.value || form.transactionType === TransactionType.Conversion;
  const counterpartyId = form.transactionType === TransactionType.Conversion
    ? form.account.id
    : (hasCounterparty ? form.counterparty.id : undefined);

  const netAmount = new Decimal(nr(form.account.netAmount)).toNumber();


  return {
    portfolioId: portfolio.value?.id,
    date: form.date,
    transactionType: form.transactionType,
    description: form.description,
    account: {
      id: form.account.id,
      currencyCode: form.account.currencyCode,
      quantity: netAmount,
      grossAmount: netAmount,
      netAmount,
      fee: 0,
      feeCurrencyCode: form.account.feeCurrencyCode,
      tax: 0,
      taxCurrencyCode: form.account.taxCurrencyCode,
      price: 1,
    },
    counterparty: {
      id: counterpartyId,
      currencyCode: form.counterparty.currencyCode,
      netAmount: netAmount,
      grossAmount: netAmount,
      fee: 0,
      feeCurrencyCode: form.counterparty.feeCurrencyCode,
      tax: 0,
      taxCurrencyCode: form.counterparty.taxCurrencyCode,
      price: 1
    }
  }
})


const beforeApiCall = () => {
  isLoading.value = true;
}

const submit = async (addNew: boolean = false) => {
  beforeApiCall();

  await loanStore.createLoanAccountTransaction(loan.value.id, finalForm.value)
    .then(() => {
      app.showNotice(t('loan_page.messages.transaction_created'), 'success');
      if (addNew) {
        resetFormForNew();
      } else {
        if (isHoldingMode.value) {
          return emit('updated')
        }
        router.push({ name: 'loan', params: { loanId: loan.value.id } });
      }
    })
    .catch(() => {})
    .finally(() => {
      isLoading.value = false;
    })
}

const onUpdate = async () => {
  beforeApiCall();

  await loanStore.updateLoanAccountTransaction(loan.value.id, transaction.value.id as any, finalForm.value)
    .then(() => {
      if (isHoldingMode.value) {
        return emit('updated')
      }
      router.push({ name: 'loan', params: { loanId: loan.value.id } });
    })
    .catch(() => {
      isLoading.value = false;
    })
}

const onDelete = async () => {
  await loanStore.deleteLoanAccountTransaction(loan.value.id, transaction.value.id as any)
    .then(() => {
      if (isHoldingMode.value) {
        return emit('updated')
      }
      router.push({ name: 'loan', params: { loanId: loan.value.id } });
    })
    .catch(() => {
      isLoading.value = false;
    })
}

const isFormLoading = computed(() => {
  return loanStore.transaction.loading || loanStore.loan.loading;
})

const amountRules = computed(() => {
  const list = []

  if (form.transactionType === TransactionType.Opening) {
    list.push(rules.value.negative)
  }

  return list
})
</script>

<template>
  <form-container
    color="orange"
    ref="formRef"
    v-model="isValid"
    :title="translations.title"
    :is-form-loading="isFormLoading"
    :is-button-loading="isLoading"
    :mode="isUpdate ? 'update' : 'create'"
    :action="action"
    @submit="(addNew: boolean = false) => isUpdate ? onUpdate() : submit(addNew)"
    @delete="onDelete"
    show-add-new
  >
    <v-row>
      <v-col cols="12" lg="12" v-if="showAccountSelector">
        <v-autocomplete
          v-bind="(fieldDesign as any)"
          :rules="[rules.required]"
          v-model="selectedLoan"
          :items="cashAccounts"
          :label="t('account_page.form.account_field')"
          item-title="name"
          item-value="id"
          return-object
          clearable
        />
      </v-col>
      <template v-if="!showAccountSelector || selectedLoan">
        <v-col cols="12" lg="6">
          <DateField
            v-model="form.date"
            :label="t('label.date')"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <v-select
            v-bind="(fieldDesign as any)"
            :rules="[rules.required]"
            :items="transactionTypeOptions"
            :label="t('label.transaction_type')"
            v-model="form.transactionType"
            :hint="form.transactionType ? t(`loanTransactionType.${form.transactionType}.hint`) : undefined"
          />
        </v-col>
        <v-col cols="12" lg="12" v-if="showRelatedAccount">
          <AccountSearchField
            :fieldDesign="fieldDesign"
            v-model="form.counterparty.id"
            :label="t('label.account')"
            :disabled="isUpdate && !!transaction?.counterparty?.id"
            :hint="t('help.account')"
            :currency-code="form.account.currencyCode"
          />
        </v-col>
        <v-col cols="12" lg="12">
          <AmountField
            v-model="form.account.netAmount"
            v-model:currency-code-value="form.account.currencyCode"
            :label="t('label.sum')"
            :rules="amountRules"
          />
        </v-col>
        <v-col cols="12" lg="12">
          <v-text-field
            v-bind="(fieldDesign as any)"
            type="text"
            v-model="form.description"
            :label="t('label.description')"
          />
        </v-col>
      </template>
    </v-row>
  </form-container>
</template>
