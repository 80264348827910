<script setup lang="ts">
import AuthLogin from '../authForms/AuthLogin.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import AuthCard from './AuthCard.vue';
import { useAppStore } from '@/stores/app';

const route = useRoute();
const appStore = useAppStore();
const { t, locale } = useI18n();

const isEmailConfirmed = ref(route.query.confirmed !== undefined);

const translations = computed(() => {
  return {
    regularTitle: t('login_page.title'),
    emailConfirmedTitle: t('login_page.email_confirmed_title')
  }
})

const title = computed(() => {
  return isEmailConfirmed.value ? translations.value.emailConfirmedTitle : translations.value.regularTitle;
})

const my_awesome_script = document.createElement('script');
my_awesome_script.setAttribute('src','https://accounts.google.com/gsi/client');
document.head.appendChild(my_awesome_script);
</script>

<template>
  <AuthCard :title="title" v-if="!isEmailConfirmed">
    <template v-if="appStore.siteConfig?.alerts">
      <v-alert v-for="(alert, i) in appStore.siteConfig?.alerts" :key="i" :color="alert.color" :title="alert.title[locale]" variant="tonal" class="mt-3">
        {{ alert.message[locale] }}
      </v-alert>
    </template>
    <AuthLogin />
  </AuthCard>
</template>