import { defineStore } from 'pinia';
import { fetchWrapper } from '@/utils/helpers/fetch-wrapper';
import { ref } from 'vue';
import type { InstrumentDtoExtendedType, UpdateCustomInstrumentDtoType } from '~/instrument.schema';

export const useInstrumentStore = defineStore('instrument', () => {
  const instrumentCache: any = {};

  const instrument = ref<{isLoading: boolean, data: null | InstrumentDtoExtendedType}>({
    isLoading: true,
    data: null
  })

  const search = async (keyword: string, filter = {}): Promise<any> => {
    const filters = new URLSearchParams(
      Object.entries(filter).reduce((acc, [key, value]) => {
        if (value) {
          acc.append(key, value as string)
        }
        return acc
      }, new URLSearchParams())
    ).toString()
    return fetchWrapper.get(`/api/v1/instrument/search?keyword=${keyword}${filters ? `&${filters}` : ''}`)
      .then((response) => {
        return response
      })
  }

  const getById = async (instrumentId: number): Promise<any> => {
    instrument.value.isLoading = true

    return Promise.resolve().then(() => {
      if (instrumentCache[instrumentId] && instrumentCache[instrumentId].ttl > Date.now()) {
        return instrumentCache[instrumentId].data;
      } else {
        instrumentCache[instrumentId] = {
          ttl: Date.now() + 5000,
          data: fetchWrapper.get(`/api/v1/instrument/${instrumentId}`)
        }
        return instrumentCache[instrumentId].data;
      }
    })
      .then((response) => {
        instrument.value.data = response
        return response
      })
      .catch((error) => {
        instrument.value.data = null
        return null;
      })
      .then((response) => {
        instrument.value.isLoading = false
        return response
      })
  }

  const updateCustomInstrument = async (instrumentId: number, data: UpdateCustomInstrumentDtoType): Promise<InstrumentDtoExtendedType> => {
    return fetchWrapper.put(`/api/v1/instrument/${instrumentId}`, data)
      .then((response) => {
        if (instrument.value.data?.id === instrumentId) {
          instrument.value.data = response
        }
        return response
      })
  }

  const deleteCustomInstrument = async (instrumentId: number): Promise<boolean> => {
    return fetchWrapper.delete(`/api/v1/instrument/${instrumentId}`)
      .then((response) => {
        return response
      })
  }

  const getAllCustom = async (): Promise<any> => {
    return fetchWrapper.get(`/api/v1/instrument/custom`)
      .then((response) => {
        return response
      })
  }

  const getAll = async (): Promise<any> => {
    return fetchWrapper.get(`/api/v1/instrument`)
      .then((response) => {
        return response
      })
  }

  const getAllByPortfolioId = async (portfolioId: number): Promise<any> => {
    return fetchWrapper.get(`/api/v1/instrument?portfolioId=${portfolioId}`)
      .then((response) => {
        return response
      })
  }

  const triggerSelect = async (uniqueId: string): Promise<any> => {
    return fetchWrapper.get(`/api/v1/instrument/${uniqueId}/select`)
      .then((response) => {
        return response
      })
  }

  return {
    instrument,
    search,
    getById,
    getAllCustom,
    getAll,
    getAllByPortfolioId,
    updateCustomInstrument,
    deleteCustomInstrument,
    triggerSelect
  }
});
