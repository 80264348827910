import { InstrumentType, TransactionType } from "../enums";
import { ShareConfig } from "./Share.config";

class FundConfig extends ShareConfig {
  type: InstrumentType = InstrumentType.ManagedFund;

  canBeMigratedToPublic: boolean = true;

  allowedTransactionTypes = [
    TransactionType.Buy,
    TransactionType.Sell,
    TransactionType.Opening,
    TransactionType.Split
  ]

  allowedIncomeTypes = [
    TransactionType.Dividend,
    TransactionType.Interest
  ]
}

export default new FundConfig();
