<script setup lang="ts">
import { currencyFormatter } from '@/const';
import type { PropType } from 'vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useTheme } from 'vuetify';
import type { PortfolioDtoType } from '~/portfolio.schema';

const { t } = useI18n();

const props = defineProps({
  values: {
    type: Array,
    required: true
  },
  isLoading: {
    type: Boolean,
    required: true
  },
  currencyCode: {
    type: String,
    required: false
  },
  round: {
    type: Boolean,
    required: false,
    default: true
  },
  label: {
    type: String,
    required: false
  },
  valueKey: {
    type: String,
    required: false,
    default: 'value'
  },
  portfolio: {
    type: Object as PropType<PortfolioDtoType>,
    required: false
  }
});

const theme = useTheme();

function generateHighchartSeries(startDate, endDate, initialAmount, targetAmount, type = 'linear') {
    // Parse the input dates
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the number of days between start and end dates
    const days = Math.ceil((end - start) / (1000 * 60 * 60 * 24));

    // Calculate the daily growth rate
    let dailyRate = Math.pow(targetAmount / initialAmount, 1 / days);
    if (type === 'linear') {
      dailyRate = (targetAmount - initialAmount) / days / initialAmount + 1;
    }

    // Initialize the series data array
    const seriesData = [];

    // Generate the coordinates
    let currentAmount = initialAmount;
    let currentDate = new Date(start);
    for (let i = 0; i <= days; i++) {
        // Push the current date (X) and amount (Y) to the series data array
        seriesData.push([
            Date.UTC(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate()), // X value
            Math.round(currentAmount) // Y value
        ]);

        // Update the current amount for the next day
        if (type === 'linear')
          currentAmount += (targetAmount - initialAmount) / days;
        else
          currentAmount *= dailyRate;

        // Move to the next day
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return seriesData;
}

const chartOptions = computed(() => {
  const valueKey =  props.valueKey;
  const isDark = theme.current.value.dark;
  const minValue = props.values.map(x => x).sort((b, a) => b[valueKey] - a[valueKey])[0];
  const maxValue = props.values.map(x => x).sort((a, b) => b[valueKey] - a[valueKey])[0];
  const isSame = minValue?.[valueKey] === maxValue?.[valueKey];
  let seriesData = [] as any[];

  if (props.portfolio && props.portfolio.goal && props.portfolio.goal.enabled) {
    const goal = props.portfolio.goal;
    seriesData = generateHighchartSeries(goal.startDate, goal.targetDate, goal.startingValue, goal.targetValue);
  }

  const goalData = seriesData.filter((val) => {
    return val[0] >= new Date(props.values[0].date).getTime() && val[0] <= new Date(props.values[props.values.length - 1].date).getTime()
  })

  if (goalData.length > 0) {
    goalData[goalData.length - 1] = [new Date(props.values[props.values.length - 1].date).getTime(), goalData[goalData.length - 1][1]];
  }

  return {
    chart: {
      type: 'area',
      height: 350,
      backgroundColor: 'transparent',
      events: {
        load: function() {
          // Find the last point in the series
          var series = this.series[1];
          var lastPoint = series.data[series.data.length - 1];
          var big = false;

          // Enable the marker for the last point
          lastPoint?.update({
            marker: {
              enabled: true,
              radius: 5,
            }
          });
          
          let interval = setInterval(() => {
            if (!lastPoint?.series?.chart) {
              clearInterval(interval);
              return;
            }
            big = !big;
            lastPoint.update({
              marker: {
                enabled: true,
                radius: big ? 4 : 7
              }
            })
          }, 500)
        }
      }
    },
    exporting: {
      enabled: false
    },
    title:{
      text:''
    },
    xAxis: {
      type: 'datetime',
      visible: true,
      minPadding: 0,
      maxPadding: 0,
      labels: {
        enabled: true,
        style: {
          color: isDark ? '#ffffff' : '#0C4160'
        }
      },
      minorTicks: false,
      gridLineWidth: 1,
      gridLineDashStyle: 'dash',
      gridLineColor: isDark ? '#32323e' : 'rgb(222, 231, 248)',
      lineColor: isDark ? '#32323e' : '#0C4160'
    },
    legend:{
      enabled: goalData.length > 0,
      itemStyle: {
        color: isDark ? '#ffffff' : '#0C4160'
      }
    },
    annotations: [{
      draggable: '',
      visible: !isSame,
      zIndex: 5,
      labelOptions: {
        shape: 'callout',
        align: 'center',
        justify: true,
        overflow: 'none',
        crop: false,
        distance: 10,
        backgroundColor: theme.current.value.colors.success,
        borderWidth: 0,
        style: {
          fontSize: '14px',
          textOutline: '0px white',
          color: isDark ? '#ffffff' : '#FFFFFF',
        }
      },
      labels: [{
        point: {
            xAxis: 0,
            yAxis: 0,
            x: new Date(maxValue?.date).getTime(),
            y: maxValue?.[valueKey],
        },
        text: `<span class="balance-sm">${currencyFormatter(maxValue?.[valueKey], props.currencyCode, props.round ? 0 : undefined, props.round ? 0 : undefined)}</span>`,
        useHTML: true,
        crop: false,
        overflow: 'allow',
      }]
    }, {
      draggable: '',
      zIndex: 5,
      visible: !isSame,
      labelOptions: {
        shape: 'callout',
        align: 'center',
        justify: true,
        overflow: 'none',
        crop: false,
        distance: 10,
        borderWidth: 0,
        backgroundColor: theme.current.value.colors.error,
        style: {
          fontSize: '14px',
          textOutline: '0px white',
          color: isDark ? '#ffffff' : '#FFFFFF',
        }
      },
      labels: [{
        point: {
            xAxis: 0,
            yAxis: 0,
            x: new Date(minValue?.date).getTime(),
            y: minValue?.[valueKey],
        },
        text: `<span class="balance-sm">${currencyFormatter(minValue?.[valueKey], props.currencyCode, props.round ? 0 : undefined, props.round ? 0 : undefined)}</span>`,
        useHTML: true,
        crop: false,
        overflow: 'allow',
      }]
    }],
    yAxis: {
      visible: false,
      minPadding: 0.1,
      maxPadding: 0.1,
      min: null,
      title: {
        text: null
      },
      gridLineWidth: 0,
      minorGridLineWidth: 0,
      labels: {
        enabled: true,
        style: {
          color: isDark ? '#ffffff' : '#0C4160'
        }
      },
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        lineWidth: 3,
      },
      area: {
        fillColor: {
          linearGradient: {
            x1: 1,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, isDark ? 'rgba(54, 153, 255, 0.5)' : 'rgba(12, 65, 96, 0.8)'],
            [1, isDark ? 'rgba(54, 153, 255, 0)' : 'rgba(12, 65, 96, 0)']
        ]
        },
      }
    },
    series: [{
      name: props.label ?? t(`label.${(valueKey === 'value' ? 'networth' : 'costbase')}`),
      threshold: null,
      data: props.values.map(val => ({
        x: new Date(val.date).getTime(),
        y: val[valueKey] !== null ? (props.round ? Math.round(val[valueKey]) : val[valueKey]) : null,
      })),
      marker: {
        enabled: false
      },
      color: isDark ? 'rgb(83, 144, 217)' : '#0C4160',
      index: 1,
    }, {
      name: t('label.goal'),
      data: goalData,
      type: 'line',
      marker: {
        enabled: false
      },
      index: 0,
      dashStyle: 'ShortDash',
      lineWidth: 2,
    }]
  }
})
</script>

<template>
  <highcharts :options="chartOptions" v-if="values.length >= 0 && !isLoading" />
  <div v-else class="mt-5">
    <v-skeleton-loader :boilerplate="!isLoading" height="180" style="width:100%;" type="ossein" />
  </div>
</template>