<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { usePortfolioStore } from '@/stores/portfolioStore';
import { rules, fieldDesign } from '@/const';
import { useRouter } from 'vue-router';
import { useAppStore } from '@/stores/app';
import { useI18n } from 'vue-i18n';
import AmountField from '@/components/inputs/AmountField.vue';
import type { PortfolioDtoType } from '~/portfolio.schema';
import { Currency, PerformanceMethod, PortfolioEntityType } from '~/enums';
import FormContainer from '@/components/shared/FormContainer.vue';
import { useAuthStore } from '@/stores/authStore';
import CurrencyField from '@/components/inputs/CurrencyField.vue';
import { useDisplay } from 'vuetify';
import PortfolioPermissions from './PortfolioPermissions.vue';

const props = defineProps(['action', 'type'])

const portfolioStore = usePortfolioStore();
const portfolio = computed(() => portfolioStore.selectedPortfolio);
const appStore = useAppStore();
const authStore = useAuthStore();
const router = useRouter();
const { t } = useI18n();
const { xs } = useDisplay();

const isUpdate = computed(() => props.action === 'update');
const isLoading = ref(false);
const isFormLoading = ref(false);
const isValid = ref(false);
const tab = ref('settings');

const portfolios = computed(() => {
  return (portfolioStore.portfolios.value ?? [])
    .filter(p => !p.isConsolidated)
    .filter(p => p.currencyCode === form.value.currencyCode)
    .map(portfolio => {
      return {
        ...portfolio,
        isShared: portfolio.user?.id !== authStore.user?.id
      }
    })
    .sort((a, b) => a.isShared ? 1 : (b.isShared ? -1 : 0))
})

const translations = computed(() => {
  return {
    title: isUpdate.value
      ? t('portfolio_page.edit_title') 
      : (props.type ==='consolidated-view' 
        ? t('portfolio_page.create_consolidated_view_title') 
        : t('portfolio_page.create_title'))
  }
})

const form = ref<Partial<PortfolioDtoType>>({
  name: '',
  currencyCode: 'EUR',
  consolidatedPortfolioIds: [],
  consolidatedPortfolioAllocations: {},
  isConsolidated: props.type ==='consolidated-view',
  isPrimary: portfolioStore.portfolios.value?.length === 0,
  entityType: PortfolioEntityType.Individual,
  performanceCalculationMethod: PerformanceMethod.Compound,
  enableMonthlyOverviewEmail: true,
  goal: {
    startDate: undefined,
    targetDate: undefined,
    startingValue: undefined,
    targetValue: undefined,
    calculationMethod: 'compound',
    enabled: false
  }
})

watch(portfolio, (portfolio: PortfolioDtoType) => {
  if (!isUpdate.value) return;
  form.value.name = portfolio.name
  form.value.currencyCode = portfolio.currencyCode
  form.value.consolidatedPortfolioIds = portfolio.consolidatedPortfolioIds
  form.value.consolidatedPortfolioAllocations = portfolio.consolidatedPortfolioAllocations
  form.value.isConsolidated = portfolio.isConsolidated
  form.value.isPrimary = portfolio.isPrimary
  form.value.entityType = portfolio.entityType
  form.value.performanceCalculationMethod = portfolio.performanceCalculationMethod
  form.value.enableMonthlyOverviewEmail = portfolio.enableMonthlyOverviewEmail
  form.value.goal = portfolio.goal || {}
}, { immediate: true })

const submit = async () => {
  isLoading.value = true;

  try {
    let routeName = 'portfolio';
    let portfolioId = portfolio.value.id;

    if (isUpdate.value) {
      await portfolioStore.update(form.value);
      appStore.showNotice(t('portfolio_page.message.updated'), 'success');
    } else {
      const portfolio = await portfolioStore.create(form.value);
      portfolioId = portfolio.id;
      appStore.showNotice(t('portfolio_page.message.created'), 'success');
      routeName = form.value.isConsolidated ? 'portfolio' : 'onboarding';
    }
    router.push({ name: routeName, params: { portfolioId }});
  } finally {
    isLoading.value = false;
  }
}

const onDelete = async () => {
  try {
    await portfolioStore.del()
    appStore.showNotice(t('portfolio_page.message.deleted'), 'warning');
    router.push('/');
  } finally {
    //
  }
}

const performanceOptions = computed(() => {
  const list = [{
    value: 'compound',
    title: t('portfolio_page.form.compound')
  }, {
    value: 'simple',
    title: t('portfolio_page.form.simple')
  }]
  list.push({
    value: 'xirr',
    title: t('portfolio_page.form.xirr')
  })
  return list;
})

const isMobileView = computed(() => {
  return xs.value;
})
</script>

<template>
  <PageLayout :heading="isUpdate ? portfolio.name : ''" hideBack>
    <FormContainer
      v-model="isValid"
      ref="formRef"
      :title="translations.title"
      :mode="action"
      @submit="submit"
      @delete="onDelete"
      :is-form-loading="isFormLoading"
      :is-button-loading="isLoading"
      :confirm-text="$t('portfolio_page.form.delete_confirm')"
      :delete-button-text="$t('portfolio_page.form.delete')"
      :flavour="'tabs'"
      color="panel-heading-bg"
    >
      <div class="d-flex flex-column flex-sm-row overflow-hidden">
        <v-tabs :style="`min-width: 150px;`" v-model="tab" color="blue" :direction="!isMobileView ? 'vertical' : 'horizontal'" :class="{'mb-4': isMobileView}"  :centered="$vuetify.display.xs" :stacked="$vuetify.display.xs" :grow="$vuetify.display.xs">
          <v-tab :value="'settings'" prepend-icon="mdi-cog">
            {{ $t('portfolio_page.form.settings') }}
          </v-tab>
          <v-tab :value="'goals'" prepend-icon="mdi-bullseye">
            {{ $t('portfolio_page.form.goals') }}
          </v-tab>
          <v-tab :value="'permissions'" prepend-icon="mdi-shield-account-outline">
            {{ $t('settings_page.permissions_tab') }}
          </v-tab>
        </v-tabs>
        <v-tabs-window v-model="tab" style="width:100%;" :class="{'pa-4': true}">
          <v-tabs-window-item value="settings">
            <v-row class="pt-2">
                <v-col cols="12">
                  <v-text-field
                    v-bind="fieldDesign"
                    type="text"
                    v-model="form.name"
                    :label="form.isConsolidated ? $t('portfolio_page.form.consolidated_name') : $t('portfolio_page.form.name')"
                    :rules="[rules.required]"
                  />
                </v-col>
                <v-col cols="12">
                  <CurrencyField
                    v-model="(form.currencyCode as Currency)"
                    :label="$t('portfolio_page.form.currency_code')"
                    :rules="[rules.required]"
                    :disabled="isUpdate"
                    :helpText="$t('portfolio_page.form.currency_code_help')"
                    :allowed-currencies="['EUR', 'USD']"
                  />
                </v-col>
                <v-col cols="12" v-if="!form.isConsolidated">
                  <v-select
                    v-bind="fieldDesign"
                    :items="[{
                        value: 'individual',
                        title: $t('portfolio_page.form.individual')
                      }, {
                        value: 'company',
                        title: $t('portfolio_page.form.company')
                      }]"
                    :label="$t('portfolio_page.form.entity_type')"
                    v-model="form.entityType"
                    :rules="[]"
                  />
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-bind="fieldDesign"
                    :items="performanceOptions"
                    :label="$t('portfolio_page.form.performance_calculation_method')"
                    v-model="form.performanceCalculationMethod"
                    :rules="[]"
                  >
                    <template v-slot:append>
                      <HoverHelpIcon size="24" slot="append" :helpText="$t('portfolio_page.performance_method_help')" />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    v-bind="fieldDesign"
                    v-model="form.isPrimary"
                    :label="$t('portfolio_page.form.is_primary')"
                    :value="true"
                    density="compact"
                  />
                </v-col>
                <v-col cols="12">
                  <v-checkbox
                    class="mt-n4"
                    v-bind="fieldDesign"
                    v-model="form.enableMonthlyOverviewEmail"
                    :label="$t('portfolio_page.form.enable_monthly_overview_email')"
                    :value="true"
                    density="compact"
                  />
                </v-col>
                <v-col cols="12" v-if="form.isConsolidated">
                  <h3 class="font-weight-medium">
                    {{ $t('portfolio_page.form.consolidated_portfolios') }}:
                  </h3>
                  <div class="d-flex">
                    <v-table class="mt-5" density="compact">
                      <thead>
                        <tr class="bg-table-heading-bg">
                          <th>{{ $t('label.portfolio') }}</th>
                          <th>
                            <div class="d-flex align-center ga-1">
                              {{ $t('portfolio_page.form.table.allocation') }}
                              <HoverHelpIcon 
                                size="16"
                                slot="append"
                                :helpText="$t('portfolio_page.form.table.allocation_help')"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="portfolio in portfolios" :key="portfolio.id">
                          <td style="padding-left:3px;">
                            <div class="d-flex align-center">
                              <div style="min-width:40px;">
                                <v-checkbox
                                  v-bind="fieldDesign"
                                  :key="portfolio.id"
                                  v-model="form.consolidatedPortfolioIds"
                                  :value="portfolio.id"
                                />
                              </div>
                              <div class="d-flex ga-1">
                                <span>{{ portfolio.name }}</span>
                                <span v-if="portfolio.user?.id !== authStore.user?.id" class="text-disabled">
                                  ({{ portfolio.user?.email }})
                                </span>
                              </div>
                            </div>
                          </td>
                          <td style="min-width:130px;">
                            <AmountField
                              v-model="form.consolidatedPortfolioAllocations[portfolio.id]"
                              :rules="[rules.min(0), rules.max(100)]"
                              :design="{density:'compact', ...fieldDesign}"
                              hideCurrency
                            >
                              <template v-slot:append-inner>
                                %
                              </template>
                            </AmountField>
                          </td>
                        </tr>
                      </tbody>
                    </v-table>
                  </div>
                </v-col>
              </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="goals">
            <v-row class="pt-2">
              <v-col cols="12">
                {{ $t('portfolio_page.goal_description') }}
              </v-col>
              <v-col cols="12">
                <v-checkbox v-bind="fieldDesign" density="compact" v-model="form.goal.enabled" :label="$t('portfolio_page.goal.enable')" />
              </v-col>
              <v-col cols="12" md="6" v-if="form.goal.enabled">
                <DateField
                  v-model="form.goal.startDate"
                  :label="t('portfolio_page.goal.start_date')"
                />
              </v-col>
              <v-col cols="12" md="6" v-if="form.goal.enabled">
                <DateField
                  v-model="form.goal.targetDate"
                  :label="t('portfolio_page.goal.target_date')"
                />
              </v-col>
              <v-col cols="12" md="6" v-if="form.goal.enabled">
                <AmountField
                  v-model="form.goal.startingValue"
                  :currency-code-value="form.currencyCode"
                  :label="t('portfolio_page.goal.starting_value')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6" v-if="form.goal.enabled">
                <AmountField
                  v-model="form.goal.targetValue"
                  :currency-code-value="form.currencyCode"
                  :label="t('portfolio_page.goal.target_value')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12" md="6" v-if="form.goal.enabled">
                <v-select
                  v-bind="fieldDesign"
                  :items="[{
                    value: 'compound',
                    title: $t('portfolio_page.goal.calculation_method_compound')
                  }, {
                    value: 'linear',
                    title: $t('portfolio_page.goal.calculation_method_linear')
                  }]"
                  :label="$t('portfolio_page.goal.calculation_method')"
                  v-model="form.goal.calculationMethod"
                  :rules="[]"
                />
              </v-col>
            </v-row>
          </v-tabs-window-item>
          <v-tabs-window-item value="permissions">
            <PortfolioPermissions :portfolioId="portfolio.id" />
          </v-tabs-window-item>
        </v-tabs-window>
      </div>
    </FormContainer>
  </PageLayout>
</template>

<style scoped lang="scss">
:deep(tbody tr:nth-of-type(even)) {
  background-color: rgba(0, 0, 0, .03);
}
:deep(table) {
  border: 1px solid #e0e0e0;
}
</style>
