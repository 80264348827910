import { InstrumentType, TransactionType } from "../enums";

export abstract class InstrumentConfig {
  abstract type: InstrumentType;
  abstract allowedTransactionTypes: TransactionType[];
  abstract allowedIncomeTypes: TransactionType[];

  defaultPrice: number = 1
  hasPrice: boolean = true
  showQuantity: boolean = true
  showQuantityInValue: boolean = true
  quantityIsCurrency: boolean = false
  canBeMigratedToPublic: boolean = false
  icon: string = '📈'

  allowRelatedHolding: Partial<Record<TransactionType, boolean>> = {
    [TransactionType.Buy]: true,
    [TransactionType.Sell]: true,
    [TransactionType.Interest]: true,
    [TransactionType.Dividend]: true,
    [TransactionType.Tax]: true,
    [TransactionType.OptionsIncome]: true,
    [TransactionType.Opening]: false,
    [TransactionType.Transfer]: true,
    [TransactionType.Withdraw]: true,
    [TransactionType.Deposit]: true,
    [TransactionType.Split]: false,
    [TransactionType.RentalIncome]: true,
    [TransactionType.InterestPaid]: true,
    [TransactionType.LoanPrincipalPaid]: true,
    [TransactionType.Expenses]: true,
    [TransactionType.OtherIncome]: true,
  }

  allowRelatedLoan = false

  allowOnCreate = {
    [TransactionType.Buy]: true,
    [TransactionType.Opening]: true
  }

  showQuantityField (_transactionType: TransactionType | undefined) {
    return true
  }

  getDefaultQuantity (_transactionType: TransactionType | undefined) {
    return undefined
  }

  showPriceField (_transactionType: TransactionType | undefined) {
    if ([
      TransactionType.Buy,
      TransactionType.Sell,
      TransactionType.BonusShares,
      TransactionType.CapitalReturn,
      TransactionType.Conversion,
      TransactionType.Opening
    ].includes(_transactionType)) {
      return this.hasPrice;
    } else {
      return false;
    }
  }
}
