<script setup lang="ts">
import { computed, ref } from 'vue';
import ConfirmDialog from '@/components/shared/ConfirmDialog.vue';
import { useI18n } from 'vue-i18n';
import DisplayCard from './DisplayCard.vue';
import CustomButton from '../inputs/CustomButton.vue';

const props = defineProps({
  modelValue: Boolean,
  title: String,
  isFormLoading: Boolean,
  isButtonLoading: Boolean,
  mode: String,
  confirmText: String,
  action: String,
  description: String,
  color: String,
  helpText: String,
  deleteButtonText: String,
  icon: String,
  flavour: String,
  showAddNew: {
    type: Boolean,
    default: false
  }
});

const isValid = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  }
});

const { t } = useI18n();
const emit = defineEmits(['submit', 'delete', 'update:modelValue']);
const formRef = ref(null);
const confirmDialog = ref(null);

const isUpdateMode = computed(() => {
  return props.mode === 'update';
});

const validate = () => {
  // @ts-expect-error
  return formRef.value.validate();
};

const triggerDelete = async () => {
  if (await confirmDialog.value.open(t('label.confirm'), props.confirmText ?? "Are you sure you want to delete this record?")) {
    emit('delete')
  }
}

const onSubmit = async (addNew: boolean = false) => {
  const { valid } = await validate();
  if (valid) {
    emit('submit', addNew);
  }
}

const translations = computed(() => ({
  buttonLabel: props.mode === 'confirm' ? t('button.confirm') : t('button.create'),
  createAndAddNew: t('button.create_and_add_new'),
  delete: props.deleteButtonText ?? t('button.delete')
}))

defineExpose({ validate });
</script>

<template>
  <template v-if="action === 'import'">
    <v-form ref="formRef" v-model="isValid">
      <slot />
      <div class="mt-6">
        <slot name="before-actions"></slot>
      </div>
    </v-form>
  </template>
  <DisplayCard :title="props.title" :help-text="helpText" :description="description" :color="color" :icon="icon" v-else>
    <template v-slot:actions>
      <slot name="title-actions"></slot>
    </template>
    <div :class="{'pa-6': flavour !== 'tabs'}">
      <v-form ref="formRef" v-model="isValid">
        <v-skeleton-loader :loading="props.isFormLoading" type="card">
          <slot />
        </v-skeleton-loader>
      </v-form>
    </div>
    <slot name="before-actions"></slot>
    <slot name="actions">
      <v-divider></v-divider>
      <div class="px-6 py-5 d-flex" v-show="!isFormLoading">
        <template v-if="isUpdateMode">
          <v-btn @click="triggerDelete" color="error" :disabled="props.isButtonLoading" variant="tonal">
            {{ translations.delete }}
          </v-btn>
          <v-spacer></v-spacer>
          <CustomButton
            @click="onSubmit"
            :is-loading="props.isButtonLoading"
            :label="$t('button.update')"
            color="blue"
          />
        </template>
        <template v-else>
          <v-spacer></v-spacer>
          <div class="d-flex ga-2 justify-end">
            <CustomButton
              @click="onSubmit"
              :is-loading="props.isButtonLoading"
              :label="translations.buttonLabel"
              color="blue"
              :variant="props.showAddNew ? 'tonal' : 'flat'"
            />
            <CustomButton
              v-if="mode === 'create' && props.showAddNew"
              @click="onSubmit(true)"
              :is-loading="props.isButtonLoading"
              :label="translations.createAndAddNew"
              color="blue"
            />
          </div>
        </template>
      </div>
    </slot>
  </DisplayCard>

  <ConfirmDialog ref="confirmDialog" />
</template>
