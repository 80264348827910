<template>
  <v-col cols="12">
    <h3 class="mb-n3">
      {{ $t('label.cashflow') }}
    </h3>
  </v-col>
  <v-col cols="12">
    <div class="d-flex flex-column ga-2">
      <div class="text-body-1">
        <template v-if="variant === 'income'">
          {{ $t('investment_page.income_config.has_fixed_income') }}
        </template>
        <template v-else>
          {{ $t('investment_page.income_config.has_fixed_income_loan') }}
        </template>
      </div>
      <div style="flex:0;">
        <button-toggle
          density="compact"
          v-model="form.fixedRateInstrumentConfig.hasFixedIncome"
          :options="[{
              title: $t('label.yes'),
              value: true
            }, {
              title: $t('label.no'),
              value: false
            }]"
          :grow="false"
        />
      </div>
    </div>
  </v-col>
  <v-col cols="12" v-if="showFields">
    <div class="d-flex flex-column ga-2">
      <div class="text-body-1">
        <template v-if="variant === 'income'">
          {{ $t('investment_page.income_config.income_type') }}
        </template>
        <template v-else>
          {{ $t('investment_page.income_config.loan_schedule_type') }}
        </template>
      </div>
      <div>
        <button-toggle
          density="compact"
          v-model="form.fixedRateInstrumentConfig.incomeType"
          :options="incomeTypeOptions"
          :grow="false"
          color="primary"
        />
      </div>
    </div>
    <v-alert v-if="showNotice" type="warning" density="compact" class="mt-4 text-body-1">
      Antud graafiku tüübi puhul ei pruugi Portfellow prognoositud rahavoog 100% ühtida tegeliku graafikuga, kuna
      finantsasutused võivad kasutada erinevaid arvutusmetoodikaid.
    </v-alert>
  </v-col>
  <v-col cols="12" md="6" v-if="showFields">
    <AmountField
      v-model="form.fixedRateInstrumentConfig.incomeAmount"
      v-model:currencyCodeValue="form.fixedRateInstrumentConfig.currencyCode"
      :label="incomeAmountLabel"
      :rules="[rules.required]"
      :hideCurrency="!hasCurrency"
      :allCurrencies="true"
    />
  </v-col>
  <v-col cols="12" md="6" v-if="showPaymentFrequency">
    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          v-bind="(fieldDesign as any)"
          :items="paymentFrequencyOptions"
          :label="$t('investment_page.income_config.payment_frequency_type')"
          :rules="[rules.required]"
          v-model="form.fixedRateInstrumentConfig.paymentFrequencyType"
          :helpText="$t('investment_page.income_config.payment_frequency_type_help')"
        />
      </v-col>
      <v-col cols="7" sm="6">
        <AmountField
          v-model="form.fixedRateInstrumentConfig.paymentFrequency"
          :label="$t('investment_page.income_config.payment_frequency')"
          :helpText="$t('investment_page.income_config.payment_frequency_help')"
          hideCurrency
        >
          <template v-slot:append-inner v-if="showPaymentFrequency && frequencyText">
            <span class="text-caption text-medium-emphasis text-right" style="white-space: nowrap;">
              {{ $t('label.explanation') }}: <strong>{{ frequencyText }}</strong>
            </span>
          </template>
        </AmountField>
      </v-col>
    </v-row>
  </v-col>
  <v-col cols="12" sm="6" v-if="showAgreementStartDate">
    <DateField
      v-model="form.fixedRateInstrumentConfig.agreementStartDate"
      :label="$t('investment_page.income_config.agreement_start_date')"
      :rules="[rules.required]"
    />
  </v-col>
  <v-col cols="12" sm="6" v-if="showFields">
    <DateField
      v-model="form.fixedRateInstrumentConfig.dateOfFirstPayment"
      :label="$t('investment_page.income_config.first_payment_date')"
      :rules="[rules.required]"
    />
  </v-col>
  <v-col cols="12" sm="6" v-if="showAgreementStartDate">
    <DateField
      v-model="form.fixedRateInstrumentConfig.dateOfLastPayment"
      :label="$t('investment_page.income_config.last_payment_date')"
      :rules="endDateRules"
      :required="false"
    />
  </v-col>
</template>

<script setup lang="ts">
import { fieldDesign, rules } from '@/const';
import isNil from "lodash/isNil";
import { FixedIncomeType, InstrumentType, PaymentFrequency } from '~/enums';
import { paymentFrequencyOptions } from '@/utils/instrument.utils';
import { computed } from 'vue';
import { watch } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  form: {
    type: null,
    required: true
  },
  variant: {
    type: String,
    default: 'income'
  },
  instrumentType: {
    type: String,
    default: null
  }
})

const { t } = useI18n();

const showFields = computed(() => {
  return !isNil(props.form.fixedRateInstrumentConfig) && props.form.fixedRateInstrumentConfig.hasFixedIncome;
})

const showPaymentFrequency = computed(() => {
  return showFields.value && props.form.fixedRateInstrumentConfig.incomeType !== FixedIncomeType.FullBullet;
})

const showAgreementStartDate = computed(() => {
  return showFields.value && props.form.fixedRateInstrumentConfig.incomeType !== FixedIncomeType.Dps;
})

const incomeAmountLabel = computed(() => {
  if (props.form.fixedRateInstrumentConfig.incomeType === FixedIncomeType.Dps) {
    return  t('label.dividend_per_share');
  } else if (props.form.fixedRateInstrumentConfig.incomeType === FixedIncomeType.RentalIncome) {
    return t('label.rental_income');
  } else if (props.form.fixedRateInstrumentConfig.incomeType === FixedIncomeType.FixedIncome) {
    return t('label.sum')
  } else {
    return t('label.interest_rate_yearly');
  }
})

const hasCurrency = computed(() => {
  return [
    FixedIncomeType.Dps,
    FixedIncomeType.FixedIncome,
    FixedIncomeType.RentalIncome
  ].includes(props.form.fixedRateInstrumentConfig.incomeType);
})

const frequencyText = computed(() => {
  const type = props.form.fixedRateInstrumentConfig.paymentFrequencyType;
  const frequency = parseInt(props.form.fixedRateInstrumentConfig.paymentFrequency || 0) || 1;

  if (!type || !frequency) {
    return '';
  }

  if (type === PaymentFrequency.Daily) {
    if (frequency === 1) {
      return 'Iga päev';
    } else {
      return `Iga ${frequency} päeva tagant`;
    }
  } else if (type === PaymentFrequency.Weekly) {
    if (frequency === 1) {
      return 'Iga nädal';
    } else {
      return `Iga ${frequency} nädala tagant`;
    }
  } else if (type === PaymentFrequency.Monthly) {
    if (frequency === 1) {
      return 'Iga kuu';
    } else {
      return `Iga ${frequency} kuu tagant`;
    }
  } else if (type === PaymentFrequency.Quarterly) {
    if (frequency === 1) {
      return 'Iga kvartal';
    } else {
      return `Iga ${frequency} kvartali tagant`;
    }
  } else if (type === PaymentFrequency.Yearly) {
    if (frequency === 1) {
      return 'Iga aasta';
    } else {
      return `Iga ${frequency} aasta tagant`;
    }
  }
})

const endDateRules = computed(() => {
  const _rules = [];

  if (![FixedIncomeType.InterestOnly, FixedIncomeType.RentalIncome].includes(props.form.fixedRateInstrumentConfig.incomeType)) {
    _rules.push(rules.value.required);
  }

  return _rules;
})

const showNotice = computed(() => {
  return [FixedIncomeType.EvenPrincipal, FixedIncomeType.EvenTotal].includes(props.form.fixedRateInstrumentConfig.incomeType);
})

const hasInterest = computed(() => {
  return [
    InstrumentType.Loan,
    InstrumentType.Bond,
    InstrumentType.Cash,
    InstrumentType.Deposit,
    InstrumentType.TakenLoan,
  ].includes(props.instrumentType);
})

const hasDividend = computed(() => {
  return [
    InstrumentType.Equity,
    InstrumentType.ExchangeTradedFund,
    InstrumentType.OrdinaryShare,
    InstrumentType.Other,
    InstrumentType.P2PLoan,
  ].includes(props.instrumentType);
})

const hasRentalIncome = computed(() => {
  return [
    InstrumentType.RealEstate,
  ].includes(props.instrumentType);
})

const hasFixedIncome = computed(() => {
  return hasInterest.value || hasDividend.value;
})

const incomeTypeOptions = computed(() => {
  return [{
    title: 'Fikseeritud summa',
    value: FixedIncomeType.FixedIncome,
    help: 'Regulaarselt makstav fikseeritud intress, üür või muu sissetulek',
    visible: hasFixedIncome.value,
  }, {
    title: 'Üüritulu',
    value: FixedIncomeType.RentalIncome,
    help: 'Regulaarselt makstav üüritulu',
    visible: hasRentalIncome.value,
  }, {
    title: 'Bullet',
    value: FixedIncomeType.Bullet,
    help: 'Intresse makstakse jooksvalt, kuid põhiosa tagastatakse korraga lõppkuupäeval. Näiteks käituvad nii võlakirjad.',
    visible: hasInterest.value,
  }, {
    title: 'Full Bullet',
    value: FixedIncomeType.FullBullet,
    help: 'Intressi ja põhiosa tagastatakse korraga lõppkuupäeval.',
    visible: hasInterest.value,
  }, {
    title: 'Ainult intressid',
    value: FixedIncomeType.InterestOnly,
    help: 'Intressi makstakse jooksvalt, kuid põhiosa tagasimakse kuupäeva pole teada. Näiteks käituvad nii AT1 võlakirjad.',
    visible: hasInterest.value,
  }, {
    title: 'Intress + põhiosa',
    value: FixedIncomeType.EvenTotal,
    help: 'Intressi ja põhiosa makstakse tagasi jooksvalt, kuine tagasimakse on perioodi vältel sama.',
    visible: hasInterest.value,
  }, {
    title: 'Intress + võrdne põhiosa',
    value: FixedIncomeType.EvenPrincipal,
    help: 'Intressi ja põhiosa makstakse tagasi jooksvalt, kuid põhiosa tagasimakse on perioodi vältel sama.',
    visible: hasInterest.value,
  }, {
    title: 'Dividend',
    value: FixedIncomeType.Dps,
    help: 'Regulaarselt makstav dividend',
    visible: hasDividend.value,
  }].filter((option) => option.visible);
})

watch(() => incomeTypeOptions, (options) => {
  const selectedType = props.form.fixedRateInstrumentConfig.incomeType;
  if (!selectedType || !options.value.map((o) => o.value).includes(selectedType)) {
    props.form.fixedRateInstrumentConfig.incomeType = options.value[0].value;
  }
}, { immediate: true })
</script>