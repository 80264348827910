<template>
  <v-progress-circular v-if="showLoader" indeterminate color="primary" />
  <v-row v-else :style="`max-width:100%;margin-left:auto;margin-right:auto;`" no-gutters>
    <slot name="header">
      <v-col v-if="heading" cols="12" :class="{'flex-column': hideBack, 'flex-column-reverse': !hideBack}" class="d-flex flex-sm-row justify-space-between align-sm-center ga-5">
        <h1 id="page-header" class="heading font-weight-black text-darkprimary" >
          <slot name="heading">{{ heading }}</slot>
        </h1>
        <div v-if="$slots.actions || $slots['before-back'] || $slots['after-back'] || !hideBack">
          <slot name="actions">
            <div class="d-flex ga-2 justify-center">
              <slot name="before-back"></slot>
              <v-btn v-if="!hideBack" class="flex-grow-1 flex-sm-grow-0" size="x-large" variant="tonal" color="secondary" @click="back">
                <v-icon left>mdi-arrow-left</v-icon>
                {{ $t('label.back') }}
              </v-btn>
              <slot name="after-back"></slot>
            </div>
          </slot>
        </div>
      </v-col>
    </slot>
    <v-col class="mt-5" :style="`max-width:${maxWidth ? maxWidth + 'px' : '100%'};margin-left:auto;margin-right:auto;`">
      <slot />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

defineProps({
  heading: {
    type: String,
    required: false
  },
  hideBack: {
    type: Boolean,
    default: false
  },
  showLoader: {
    type: Boolean,
    default: false
  },
  maxWidth: {
    default: undefined
  }
})

const router = useRouter();

const back = () => {
  router.go(-1);
}
</script>